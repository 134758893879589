import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Team from '../components/Team'
import { Helmet } from 'react-helmet'

export default function BstcTravel() {
    return (
        <>
            <Helmet>
                <title>BSTravel: Redefining Journeys in the Web3 Era</title>
                <meta name="title" content="BSTravel: Redefining Journeys in the Web3 Era" />
                <meta name="description" content="Explore the future of travel with BSTravel's innovative approach in the Web3 era. Discover immersive experiences, seamless bookings, and unparalleled adventures." />
                <meta name="keywords" content="Travel, Web3, Journeys, BSTravel, Blockchain, Innovation, Tourism, Adventure, Virtual Travel, Crypto Travel, NFT Travel, Digital Travel, Smart Travel, Travel Revolution, Metaverse Travel, Travel Technology, Travel Innovation, BST Travel, Travel Industry, Crypto Trends, Web3 Travel, Travel Metaverse, Blockchain Travel, Travel Revolution, Digital Trends, BST Trends." />
                <link rel="canonical" href="https://bstgroup.world/bstravel" />
            </Helmet>
            <Header />

            <div className='main-page-wrapper'>

                <div
                    className="inner-banner-one pt-225 lg-pt-200 md-pt-150 pb-100 md-pb-70 position-relative"
                    style={{ backgroundImage: "url(../assets/image/srvices/travel.jpg)" }}
                >
                    <div className="container position-relative">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <h1 className="hero-heading text-white d-inline-block position-relative me-xl-4">
                                    BSTravel{" "}
                                </h1>
                            </div>
                            <div className="col-xl-4 col-lg-5 ms-auto">
                                <p className="text-white text-lg mb-70 lg-mb-40">
                                    BSTravel is your premier online travel agency, offering hassle-free booking and personalized experiences for seamless journeys worldwide.
                                </p>
                                <ul className="style-none d-inline-flex pager">
                                    <li>
                                        <a href="#">Explore Now</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-details mt-150 lg-mt-80 mb-100 lg-mb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 order-lg-last">
                                <div className="details-meta ps-xxl-5 ps-xl-3">
                                    <h2>Premier online agency for seamless global journeys.</h2>
                                    <p>
                                        Experience seamless travel planning with BSTravel's intuitive booking platform, diverse travel options, and personalized service, making every journey memorable and stress-free.{" "}
                                    </p>
                                    <p>
                                        Discover a world of possibilities with BSTravel's customer-centric approach, transparent pricing, and round-the-clock assistance, ensuring a smooth and enjoyable travel experience from start to finish.
                                    </p>
                                    <h3 className='mt-4'>Tailored Trips</h3>
                                    <p>
                                        BSTravel leverages blockchain technology for curated experiences, personalized service, and 24/7 support for global journeys.
                                    </p>
                                    <div className="line-wrapper pb-60 mt-60 lg-mt-40">
                                        <div className="row">
                                            <div className="col-md-4 wow fadeInUp">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_81.svg"
                                                            data-src="assets/image/icon_81.svg"
                                                            alt="Effortless Booking"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">
                                                        Effortless Booking
                                                    </h4>
                                                    <p className="m0">
                                                        Utilizing blockchain for streamlined flight, hotel, and activity reservations.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 wow fadeInUp" data-wow-delay="0.1s">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_82.svg"
                                                            data-src="assets/image/icon/icon_82.svg"
                                                            alt="Personalized Service"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">
                                                        Personalized Service
                                                    </h4>
                                                    <p className="m0">
                                                        Blockchain-powered support tailored to individual needs and preferences.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 wow fadeInUp" data-wow-delay="0.2s">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_83.svg"
                                                            data-src="assets/image/icon/icon_83.svg"
                                                            alt="Transparent Pricing"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">Transparent Pricing</h4>
                                                    <p className="m0">
                                                        Clear blockchain-based rates with no hidden fees, ensuring transparency.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Team />

                <Footer />
            </div >
        </>
    )
}