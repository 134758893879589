import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Team from '../components/Team'
import { Helmet } from 'react-helmet'

export default function BSTBank() {
    return (
        <>
            <Helmet>
                <title>BST Bank: Empowering Financial Transactions in the Web3 World</title>
                <meta name="title" content="BST Bank: Empowering Financial Transactions in the Web3 World" />
                <meta name="description" content="Discover seamless financial solutions with BST Bank, leveraging blockchain technology for secure transactions in the Web3 world." />
                <meta name="keywords" content="Bank, Finance, Web3, BST Bank, Blockchain, Innovation, Financial Services, Banking, Crypto Bank, NFT Bank, Digital Bank, Finance Revolution, Banking Technology, BST Bank, Financial Innovation, Web3 Finance, Blockchain Finance, Digital Finance, Crypto Finance, Banking Trends, Web3 Banking, BST Trends, Financial Trends" />
                <link rel="canonical" href="https://bstgroup.world/bst-bank" />
            </Helmet>
            <Header />

            <div className='main-page-wrapper'>

                <div
                    className="inner-banner-one pt-225 lg-pt-200 md-pt-150 pb-100 md-pb-70 position-relative"
                    style={{ backgroundImage: "url(../assets/image/srvices/bank.jpg)" }}
                >
                    <div className="container position-relative">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <h1 className="hero-heading text-white d-inline-block position-relative me-xl-4">
                                    BST Bank{" "}
                                </h1>
                            </div>
                            <div className="col-xl-4 col-lg-5 ms-auto">
                                <p className="text-white text-lg mb-70 lg-mb-40">
                                    Access comprehensive banking and investment services tailored for the Web3 era.
                                </p>
                                <ul className="style-none d-inline-flex pager">
                                    <li>
                                        <a href="#">Explore Now</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-details mt-150 lg-mt-80 mb-100 lg-mb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 order-lg-last">
                                <div className="details-meta ps-xxl-5 ps-xl-3">
                                    <h2>Empowering Your Web3 Financial Journey with Blockchain Solutions</h2>
                                    <p>
                                        It leads the charge in Web3 finance, providing a gateway to empowerment in the digital age. From innovative banking solutions to flexible loans and robust investment platforms, we cater to the needs of blockchain enthusiasts and entrepreneurs alike.{" "}
                                    </p>
                                    <p>
                                        Our commitment to seamless user experiences and financial inclusion drives every aspect of our service. With intuitive digital platforms and a focus on accessibility, BST Bank is your trusted partner for navigating the complexities of the Web3 landscape.
                                    </p>
                                    <h3 className='mt-4'>Blockchain Banking</h3>
                                    <p>
                                        Explore innovative banking, DeFi loans, token investments, and inclusive finance initiatives designed for the Web3 era and blockchain communities.
                                    </p>
                                    <div className="line-wrapper pb-60 mt-60 lg-mt-40">
                                        <div className="row">
                                            <div className="col-md-4 wow fadeInUp">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_81.svg"
                                                            data-src="assets/image/icon_81.svg"
                                                            alt="Crypto Banking"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">
                                                        Crypto Banking
                                                    </h4>
                                                    <p className="m0">
                                                        Access cutting-edge banking services tailored for the Web3 era.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 wow fadeInUp" data-wow-delay="0.1s">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_82.svg"
                                                            data-src="assets/image/icon/icon_82.svg"
                                                            alt="DeFi Loans"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">
                                                        DeFi Loans
                                                    </h4>
                                                    <p className="m0">
                                                        Flexible loans with competitive rates, powered by blockchain technology.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 wow fadeInUp" data-wow-delay="0.2s">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_83.svg"
                                                            data-src="assets/image/icon/icon_83.svg"
                                                            alt="Token Investments"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">Token Investments</h4>
                                                    <p className="m0">
                                                        Diverse investment products include tokens, NFTs, and cryptocurrencies.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Team />
                <Footer />
            </div >
        </>
    )
}