import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export default function Career() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNumber: '',
    position: '',
    description: '',
    attachment: null,
  });

  const [validation, setValidation] = useState({
    name: false,
    email: false,
    contactNumber: false,
    position: false,
    description: false,
    attachment: false,
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name == 'contactNumber' && !/^[0-9]+$|$^/.test(value)) return;
    setValidation({ ...validation, [name]: false });
    setFormData({ ...formData, [name]: value });
  };

  const chackValidations = () => {
    if (loading) return;
    if (!formData.name) {
      setValidation((prev) => ({ ...prev, name: true }));
      return;
    }
    if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
    ) {
      setValidation((prev) => ({ ...prev, email: true }));
      return;
    }
    if (formData.contactNumber?.length < 10) {
      setValidation((prev) => ({ ...prev, contactNumber: true }));
      return;
    }
    if (!formData.position) {
      setValidation((prev) => ({ ...prev, position: true }));
      return;
    }
    if (!formData.description) {
      setValidation((prev) => ({ ...prev, description: true }));
      return;
    }
    if (!formData.attachment) {
      setValidation((prev) => ({ ...prev, attachment: true }));
      return;
    }
    handleSubmit();
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const fd = new FormData();

      fd.append('_subject', 'Career Inquiry');
      Object.keys(formData).forEach((key) => fd.append(key, formData[key]));

      const resp = await axios.post(
        'https://formsubmit.co/ajax/<ID HERE>',
        fd,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          BST Group Careers | Explore Career Opportunities | Pioneering the
          Future
        </title>
        <meta
          name='title'
          content='BST Group Careers | Explore Career Opportunities | Pioneering the Future'
        />
        <meta
          name='description'
          content='Discover rewarding career opportunities at BST Group, where innovation, technology, and excellence converge. Join our team of driving industry revolution and growth.'
        />
        <meta
          name='keywords'
          content='Careers, Jobs, Employment, BST Group, Innovation, Technology, Excellence, Industry Revolution, Growth Opportunities, Career Development, Job Openings, Workplace Culture, Talent Acquisition, Innovation Careers, Technology Jobs, Industry Disruption, Professional Growth, Career Path, Visionary Leadership, Team Collaboration, Future Vision, Career Opportunities, Visionary Leadership, Talent Recruitment, Career Growth, Blockchain career, web3 career, Metaverse career, BST Group Career, Metaverse Job, Blockchain job'
        />
        <link rel='canonical' href='https://bstgroup.world/career' />
      </Helmet>

      <Header />

      <div className='main-page-wrapper'>
        <div
          className='inner-banner-one pt-225 lg-pt-200 md-pt-150 pb-100 md-pb-70 position-relative'
          style={{ backgroundImage: 'url(../assets/image/about/contact.png)' }}
        >
          <div className='container position-relative'>
            <div className='row align-items-center'>
              <div className='col-lg-7'>
                <h1 className='hero-heading d-inline-block text-white position-relative'>
                  Join Our Team at BST
                </h1>
              </div>
              <div className='col-xl-4 col-lg-5 ms-auto'>
                <p className='text-white text-lg mb-70 lg-mb-40'>
                  Explore career opportunities at BST and take your next step
                  with us.
                </p>
                {/* <ul className="style-none d-inline-flex pager">
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>/</li>
                                    <li>Contact</li>
                                </ul> */}
              </div>
            </div>
          </div>
        </div>

        <div className='contact-us-section pt-150 lg-pt-80'>
          <div className='container'>
            <div className='position-relative'>
              <div className='bg-wrapper light-bg mb-80'>
                <div className='row'>
                  <div className='col-lg-5'>
                    <div className='d-flex flex-column flex-lg-column-reverse'>
                      <div className='row'>
                        <div className='col-md-8 col-6 me-auto ms-auto'>
                          <img
                            src='assets/image/icon/contact.svg'
                            data-src='assets/image/icon/contact.svg'
                            alt='contact'
                            className='lazy-img me-auto ms-auto'
                          />
                        </div>
                      </div>
                      <div className='title-one text-center text-lg-start md-mt-20 mb-70 md-mb-30'>
                        <h2>Apply now, attach your resume today!</h2>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-7'>
                    <div className='form-style-one ps-xl-5'>
                      <div className='messages' />
                      <div className='row controls'>
                        <div className='col-12'>
                          <div className='input-group-meta form-group mb-30'>
                            <label htmlFor=''>Name*</label>
                            <input
                              type='text'
                              placeholder='Your Name'
                              required='required'
                              data-error='Name is required.'
                              name='name'
                              value={formData.name}
                              onChange={handleChange}
                            />
                            {validation.name && (
                              <div className='validations_text'>
                                Name is required
                              </div>
                            )}
                            <div className='help-block with-errors' />
                          </div>
                        </div>
                        <div className='col-12'>
                          <div className='input-group-meta form-group mb-30'>
                            <label htmlFor=''>Email*</label>
                            <input
                              type='email'
                              placeholder='Your Email'
                              required='required'
                              data-error='Email is required.'
                              name='email'
                              value={formData.email}
                              onChange={handleChange}
                            />
                            {validation.email && (
                              <div className='validations_text'>
                                {formData.email
                                  ? 'Invalid email'
                                  : 'Email is required'}
                              </div>
                            )}
                            <div className='help-block with-errors' />
                          </div>
                        </div>
                        <div className='col-12'>
                          <div className='input-group-meta form-group mb-30'>
                            <label htmlFor=''>Contact No*</label>
                            <PhoneInput
                              country={'eg'}
                              enableSearch={true}
                              value={formData.contactNumber}
                              onChange={(phone) =>
                                setFormData({
                                  ...formData,
                                  contactNumber: phone,
                                })
                              }
                            />
                            {validation.contactNumber && (
                              <div className='validations_text'>
                                {formData.contactNumber
                                  ? 'Invalid contact number'
                                  : 'Contact number is required'}
                              </div>
                            )}
                            <div className='help-block with-errors' />
                          </div>
                        </div>
                        <div className='col-12'>
                          <div className='input-group-meta form-group mb-30'>
                            <label htmlFor=''>Position*</label>
                            <input
                              type='text'
                              placeholder='Your Position'
                              name='position'
                              value={formData.position}
                              onChange={handleChange}
                            />
                            {validation.position && (
                              <div className='validations_text'>
                                Position is required
                              </div>
                            )}
                            <div className='help-block with-errors' />
                          </div>
                        </div>
                        <div className='col-12'>
                          <div className='input-group-meta form-group mb-30'>
                            <label htmlFor=''>Description*</label>
                            <textarea
                              placeholder='Your Description'
                              required='required'
                              defaultValue={''}
                              name='description'
                              value={formData.description}
                              onChange={handleChange}
                            />
                            {validation.description && (
                              <div className='validations_text'>
                                Description is required
                              </div>
                            )}
                            <div className='help-block with-errors' />
                          </div>
                        </div>
                        <div className='col-12'>
                          <div className='input-group-meta form-group mb-30'>
                            <label htmlFor=''>Attachment*</label>
                            <input
                              type='file'
                              placeholder='Your Attachment'
                              name='attachment'
                              required='required'
                              data-error='Attachment is required.'
                              onChange={(e) => {
                                handleChange({
                                  target: {
                                    name: 'attachment',
                                    value: e.target.files[0],
                                  },
                                });
                              }}
                            />
                            {validation.attachment && (
                              <div className='validations_text'>
                                Please attach your resume
                              </div>
                            )}
                            <div className='help-block with-errors' />
                          </div>
                        </div>
                        <div className='col-12'>
                          <button
                            className='btn-four tran3s w-100 d-block'
                            onClick={chackValidations}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="map-banner mt-120 lg-mt-80">
                            <div className="gmap_canvas h-100 w-100">
                                <iframe
                                    className="gmap_iframe h-100 w-100"
                                    src="https://maps.google.com/maps?width=600&height=400&hl=en&q=dhaka%20collage&t=&z=12&ie=UTF8&iwloc=B&output=embed"
                                />
                            </div>
                        </div> */}
        </div>

        <Footer />
      </div>
    </>
  );
}