import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Team from '../components/Team'
import { Helmet } from 'react-helmet'

export default function BstcManagement() {
    return (
        <>

            <Helmet>
                <title>Assets & Wealth Management: Leading Financial Solutions in the Web3 Landscape</title>
                <meta name="title" content="Assets & Wealth Management: Leading Financial Solutions in the Web3 Landscape" />
                <meta name="description" content="Unlock financial success in the web3 landscape with BST Assets & Wealth Management, providing innovative solutions tailored to your digital wealth needs." />
                <meta name="keywords" content="Management, Wealth, Blockchain, Web3, Finance, Investment, Asset Management, Wealth Management, Crypto Management, NFT Management, Digital Management, Financial Innovation, Wealth Technology, BST Management, Wealth Revolution, Financial Services, Crypto Trends, Web3 Finance, Blockchain Finance, Digital Assets, Wealth Innovation, BST Trends, Financial Trends" />
                <link rel="canonical" href="https://bstgroup.world/bst-management" />
            </Helmet>

            <Header />

            <div className='main-page-wrapper'>
                <div
                    className="inner-banner-one pt-225 lg-pt-200 md-pt-150 pb-100 md-pb-70 position-relative"
                    style={{ backgroundImage: "url(../assets/image/srvices/wealth.jpg)" }}
                >
                    <div className="container position-relative">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <h1 className="hero-heading text-white d-inline-block position-relative me-xl-4">
                                    Assets & Wealth Management{" "}
                                </h1>
                            </div>
                            <div className="col-xl-4 col-lg-5 ms-auto">
                                <p className="text-white text-lg mb-70 lg-mb-40">
                                    Navigate financial success with tailored solutions and expert guidance from BST.
                                </p>
                                <ul className="style-none d-inline-flex pager">
                                    <li>
                                        <a href="#">Explore Now</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-details mt-150 lg-mt-80 mb-100 lg-mb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 order-lg-last">
                                <div className="details-meta ps-xxl-5 ps-xl-3">
                                    <h2>The services, expert team, and commitment to financial goals with confidence</h2>
                                    <p>
                                        Achieve financial success with our personalized services, including asset allocation and retirement planning. Our expert team collaborates closely with you to align strategies with your goals.
                                        {" "}
                                    </p>
                                    <p>
                                        Client satisfaction is paramount at BST Wealth Management. With a focus on trust and transparency, we offer ongoing support and guidance to navigate the financial landscape confidently
                                    </p>
                                    <h3 className='mt-4'>Wealth Navigation</h3>
                                    <p>
                                        BST Assets & Wealth Management empowers clients with personalized solutions and expert guidance to navigate the path to financial prosperity.
                                    </p>
                                    <div className="line-wrapper pb-60 mt-60 lg-mt-40">
                                        <div className="row">
                                            <div className="col-md-4 wow fadeInUp">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_81.svg"
                                                            data-src="assets/image/icon_81.svg"
                                                            alt="Expert Team"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">
                                                        Expert Team
                                                    </h4>
                                                    <p className="m0">
                                                        Our team includes certified financial planners, investment advisors, and estate planning specialists.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 wow fadeInUp" data-wow-delay="0.1s">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_82.svg"
                                                            data-src="assets/image/icon/icon_82.svg"
                                                            alt="Personalized Solutions"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">
                                                        Personalized Solutions
                                                    </h4>
                                                    <p className="m0">
                                                        Get personalized wealth management solutions aligned with your goals and risk tolerance.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 wow fadeInUp" data-wow-delay="0.2s">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_83.svg"
                                                            data-src="assets/image/icon/icon_83.svg"
                                                            alt="Client-centric"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">Client-centric</h4>
                                                    <p className="m0">
                                                        Experience our commitment to satisfaction, trust, and transparent relationships for your financial journey.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Team />

                <Footer />
            </div >
        </>
    )
}