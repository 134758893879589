import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <CookieConsent
      customStyles={{
        bar: { background: '#efefef', fontSize: '16px' },
        button: { background: '#4CAF50', color: '#ffffff' },
      }}
      location='bottom'
      buttonText='I agree'
      declineButtonText='Decline'
      cookieName='myAppCookieConsent'
      style={{ background: '#022346' }}
      buttonStyle={{ color: '#ffffff', fontSize: '14px', background: 'linear-gradient(180deg, #0067E0 0%, #00387A 100%)', padding: '10px 18px', borderRadius: '20px' }}
      expires={150}
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>
    <App />
    {/* </React.StrictMode> */}
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
