import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Team from '../components/Team'
import { Helmet } from 'react-helmet'

export default function BstcMetaverse() {
    return (
        <>
            <Helmet>
                <title>BST Metaverse: Shaping the Future of Web3 Virtual Realities</title>
                <meta name="title" content="BST Metaverse: Shaping the Future of Web3 Virtual Realities" />
                <meta name="description" content="Explore the limitless possibilities of Web3 virtual realities with BST Metaverse's immersive experiences and digital landscapes." />
                <meta name="keywords" content="Metaverse, Virtual Reality, VR, Blockchain, Web3, Innovation, Digital World, Crypto Metaverse, NFT Metaverse, Web3 Metaverse, Metaverse Technology, Metaverse Innovation, BST Metaverse, Metaverse Revolution, Virtual Worlds, Digital Experience, Immersive Technology, Metaverse Trends, Virtual Landscapes, Web3 Reality, Blockchain VR, Virtual Innovation, BST Trends." />
                <link rel="canonical" href="https://bstgroup.world/bst-metaverse" />
            </Helmet>
            <Header />

            <div className='main-page-wrapper'>
                <div
                    className="inner-banner-one pt-225 lg-pt-200 md-pt-150 pb-100 md-pb-70 position-relative"
                    style={{ backgroundImage: "url(../assets/image/srvices/metaverse.jpg)" }}
                >
                    <div className="container position-relative">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <h1 className="hero-heading text-white d-inline-block position-relative me-xl-4">
                                    BST Metaverse{" "}
                                </h1>
                            </div>
                            <div className="col-xl-4 col-lg-5 ms-auto">
                                <p className="text-white text-lg mb-70 lg-mb-40">
                                    Dive into the BST Metaverse's boundless digital realms for immersive experiences.
                                </p>
                                <ul className="style-none d-inline-flex pager">
                                    <li>
                                        <a href="#">Explore Now</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-details mt-150 lg-mt-80 mb-100 lg-mb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 order-lg-last">
                                <div className="details-meta ps-xxl-5 ps-xl-3">
                                    <h2>Join the BST Metaverse partnering with Blockchain.Land to explore, collaborate. </h2>
                                    <p>
                                        BST Metaverse, in collaboration with Blockchain.Land, revolutionizes virtual reality by offering immersive experiences and digital landscapes beyond the physical realm. Experience limitless possibilities and adventures with our immersive virtual experiences and captivating digital realms.{" "}
                                    </p>
                                    <p>
                                        Experience creativity, collaboration, and exploration within the metaverse, powered by Blockchain.Land. Design, build, and share virtual creations, embark on epic journeys, and connect with global communities on our innovative platform.
                                    </p>
                                    <h3 className='mt-4'>Shaping Virtual Realities</h3>
                                    <p>
                                        BST Metaverse, in collaboration with Blockchain.Land, pioneers a transformative platform, reshaping virtual reality with immersive experiences and boundless digital realms.
                                    </p>
                                    <div className="line-wrapper pb-30 mt-60 lg-mt-40 mb-70 lg-mb-40">
                                        <div className="row">
                                            <div className="col-md-4 wow fadeInUp">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_81.svg"
                                                            data-src="assets/image/icon_81.svg"
                                                            alt="Digital Landscapes"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">
                                                        Digital Landscapes
                                                    </h4>
                                                    <p className="m0">
                                                        Explore diverse landscapes, from fantasy realms to lifelike simulations, powered by Blockchain.Land's innovative technology.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 wow fadeInUp" data-wow-delay="0.1s">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_82.svg"
                                                            data-src="assets/image/icon/icon_82.svg"
                                                            alt="Creative Collaboration"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">
                                                        Creative Collaboration
                                                    </h4>
                                                    <p className="m0">
                                                        Foster creativity and collaboration through virtual design and sharing tools provided by Blockchain.Land.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 wow fadeInUp" data-wow-delay="0.2s">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_83.svg"
                                                            data-src="assets/image/icon/icon_83.svg"
                                                            alt="Exploration Thrills"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">Exploration Thrills</h4>
                                                    <p className="m0">
                                                        Embark on epic journeys, discover hidden treasures, and connect globally within the immersive world of BST Metaverse and Blockchain.Land.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        <p>
                                            BST Metaverse has collaborated with Blockchain.Land to enhance our platform's capabilities and offer users even more exciting opportunities for engagement and interaction.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Team />

                <Footer />
            </div >
        </>
    )
}