import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import News from '../components/News';
import Team from '../components/Team';
import { Helmet } from 'react-helmet';

export default function AboutUs() {
  //   const fetchVolume = async () => {
  //     try {
  //       const response = axios.get('http://localhost:8080/volume-data');
  //       //   const volume = response.data.data[symbol].quote.USD.volume_24h;
  //       console.log(response);
  //     } catch (error) {
  //       console.log(`🚀 ~ fetchVolume ~ error:`, error);
  //     }
  //   };

  //   useEffect(() => {
  //     fetchVolume();
  //   }, []);

  return (
    <>
      <Helmet>
        <title>About BST Group - Learn About Our Vision and Mission</title>
        <meta
          name='title'
          content='About BST Group - Learn About Our Vision and Mission'
        />
        <meta
          name='description'
          content="Learn about BST Group’s vision, mission, and values. Discover how we're driving innovation and shaping the future of various industries."
        />
        <meta
          name='keywords'
          content='Vision, Mission, Values, Innovation, Leadership, Team, Expertise, Industry, Experience, Transformation, Future, Technology, Revolutionize, Driving Change, Mission-driven, Success, Visionary, Shaping, Industries, Impact, Global, BST, BST Group, BST chain, Blockchain, Metaverse, Cryptocurrency, Financial Services, Digital Transformation, Virtual Reality, Digital Assets, Wealth Management, Fintech, Smart Contracts, Decentralized Finance, Crypto Exchange, NFTs, Tokenization'
        />
        <link rel='canonical' href='https://bstgroup.world/about-us' />
      </Helmet>

      <Header />

      <div className='main-page-wrapper'>
        <div
          className='inner-banner-one light-bg text-center pt-200 md-pt-150 pb-85 lg-pb-50 position-relative'
          style={{ backgroundImage: 'url(../assets/image/about/contact.png)' }}
        >
          <div className='container position-relative'>
            <div className='row'>
              <div className='col-xl-8 col-lg-9 m-auto'>
                {/* <ul className="style-none d-inline-flex justify-content-center pager">
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>/</li>
                                    <li>About us</li>
                                </ul> */}
                <h1 className='hero-heading text-white'>
                  BST Group's Visionary Journey
                </h1>
                <p className='text-lg text-white'>
                  The BST Group pioneers transformative solutions for global
                  economic empowerment
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='text-feature-three text-feature-seven position-relative mt-160 lg-mt-80 pb-200 lg-pb-120 sm-pb-80'>
          <div className='container'>
            <div className='row'>
              <div className='col-xxl-5 col-lg-6 ms-auto d-flex flex-column order-lg-last wow fadeInRight'>
                <div className='title-one'>
                  <h2>Working for a Better Tomorrow</h2>
                </div>
                <p className='text-lg mt-30 mb-55 lg-mb-30'>
                  We innovate to empower industries globally, fostering growth
                  and prosperity through cutting-edge solutions and an
                  unwavering commitment to excellence.
                </p>
                <ul className='style-none'>
                  <li>
                    Our goal is to make blockchain and Web3 easy and handy for
                    everyone, everywhere.
                  </li>
                  <li>
                    We imagine a future where blockchain and Web3 are everyday
                    tools, helping people and businesses thrive.
                  </li>
                </ul>
                <div className='counter-wrapper mt-50 lg-mt-40 pt-25 lg-pt-10'>
                  <div className='row'>
                    <div className='col-xl-6 col-sm-5'>
                      <div className='counter-block-one mt-20'>
                        <div className='main-count fw-bold'>
                          <span className='counter'>21</span>
                        </div>
                        <p className='m0'>Nodes Worldwide</p>
                      </div>
                    </div>
                    <div className='col-xl-6 col-sm-7'>
                      <div className='counter-block-one mt-20'>
                        <div className='main-count fw-bold'>
                          <span className='counter'>70</span>%
                        </div>
                        <p className='m0'>Sapphire Holders</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xxl-6 col-lg-5 d-flex order-lg-first justify-content-center wow fadeInLeft mt-lg-0 mt-30'>
                <img
                  src='../assets/image/about/about_us.png'
                  data-src=''
                  alt='about'
                  className='lazy-img screen_01'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='text-feature-two position-relative pt-110 lg-pt-80 pb-110 lg-pb-80'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-xl-6 col-lg-7'>
                <div className='title-one'>
                  <h2 className='text-white'>
                    Trusted Leadership in Visionary Ventures
                  </h2>
                </div>
              </div>
              <div className='col-lg-5 ms-auto'>
                <p className='m0 text-md text-white md-pt-10'>
                  Leading digital transformation and ventures globally,
                  fostering trust and innovation.
                </p>
              </div>
            </div>
            <div className='row gx-0 mt-50 lg-mt-20 md-mt-10'>
              <div className='col-lg-4'>
                <div className='card-style-five text-center mt-60'>
                  <div className='main-count fw-500'>
                    <span className='counter'>10</span>+ Years
                  </div>
                  <p className='ps-xxl-5 ps-xl-3 pe-xxl-5 pe-xl-3'>
                    A decade of leadership in digital transformation and
                    visionary ventures.
                  </p>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='card-style-five text-center mt-60'>
                  <div className='main-count fw-500'>
                    <span className='counter'>40</span>% +
                  </div>
                  <p className='ps-xxl-5 ps-xl-3 pe-xxl-5 pe-xl-3'>
                    Kashmi Sapphire Acquisition goes to acquiring and auctioning
                    Kashmiri Blue Sapphires, bolstering BST's stability and
                    growth.
                  </p>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='card-style-five text-center mt-60'>
                  <div className='main-count fw-500'>
                    $<span className='counter'>241</span>M
                  </div>
                  <p className='ps-xxl-5 ps-xl-3 pe-xxl-5 pe-xl-3'>
                    Trading Volume. Our track record boasts a trading volume
                    surpassing $1.3 billion, showcasing the trust of our clients
                    and partners.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='block-feature-four position-relative mt-150 lg-mt-80 pb-150 lg-pb-80'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-xl-8 col-lg-9 m-auto wow fadeInUp'>
                <div className='title-one text-center mb-50 lg-mb-20'>
                  <h2>
                    Empowering Innovation, Leading with Integrity, Impacting
                    Globally
                  </h2>
                </div>
              </div>
            </div>
            <div className='row gx-xxl-5'>
              <div className='col-lg-4 d-flex wow fadeInUp'>
                <div className='card-style-six text-center vstack tran3s w-100 mt-30'>
                  <div className='icon rounded-circle d-flex align-items-center justify-content-center m-auto'>
                    <img
                      src='assets/image/icon/icon_20.svg'
                      data-src='assets/image/about/hand.png'
                      alt='Innovative Ventures'
                      className='lazy-img'
                    />
                  </div>
                  <h4 className='fw-bold mt-40 md-mt-30 mb-25'>
                    Innovative Ventures
                  </h4>
                  <p className='mb-20'>
                    Spearheading groundbreaking initiatives for global impact.
                  </p>
                  {/* <a
                                        href="#"
                                        className="arrow-btn tran3s m-auto stretched-link"
                                    >
                                        <img
                                            src="images/lazy.svg"
                                            data-src="images/icon/icon_09.svg"
                                            alt=""
                                            className="lazy-img"
                                        />
                                    </a> */}
                </div>
              </div>
              <div
                className='col-lg-4 d-flex wow fadeInUp'
                data-wow-delay='0.1s'
              >
                <div className='card-style-six text-center vstack tran3s w-100 mt-30'>
                  <div className='icon rounded-circle d-flex align-items-center justify-content-center m-auto'>
                    <img
                      src='assets/image/icon/icon_21.svg'
                      data-src='assets/image/icon/icon_21.svg'
                      alt='Empowering Communitiess'
                      className='lazy-img'
                    />
                  </div>
                  <h4 className='fw-bold mt-40 md-mt-30 mb-25'>
                    Empowering Communities
                  </h4>
                  <p className='mb-20'>
                    Fostering diversity and empowerment through innovative
                    programs.
                  </p>
                  {/* <a
                                        href="#"
                                        className="arrow-btn tran3s m-auto stretched-link"
                                    >
                                        <img
                                            src="images/lazy.svg"
                                            data-src="images/icon/icon_09.svg"
                                            alt=""
                                            className="lazy-img"
                                        />
                                    </a> */}
                </div>
              </div>
              <div
                className='col-lg-4 d-flex wow fadeInUp'
                data-wow-delay='0.2s'
              >
                <div className='card-style-six text-center vstack tran3s w-100 mt-30'>
                  <div className='icon rounded-circle d-flex align-items-center justify-content-center m-auto'>
                    <img
                      src='assets/image/icon/icon_22.svg'
                      data-src='assets/image/icon/icon_22.svg'
                      alt='Trusted Leadership'
                      className='lazy-img'
                    />
                  </div>
                  <h4 className='fw-bold mt-40 md-mt-30 mb-25'>
                    Trusted Leadership
                  </h4>
                  <p className='mb-20'>
                    Guided by a distinguished advisory board of industry
                    experts.
                  </p>
                  {/* <a
                                        href="#"
                                        className="arrow-btn tran3s m-auto stretched-link"
                                    >
                                        <img
                                            src="images/lazy.svg"
                                            data-src="images/icon/icon_09.svg"
                                            alt=""
                                            className="lazy-img"
                                        />
                                    </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id='team' className='team-section-one mb-100 lg-mb-40'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-xl-8 col-lg-9 m-auto wow fadeInUp'>
                <div className='title-one text-center mb-50 lg-mb-20'>
                  <h2>Founders & Partners</h2>
                </div>
              </div>
            </div>
            <div className='position-relative mt-50'>
              <div className='row justify-content-center gap-lg-2'>
                <div
                  className='col-lg-3 col-sm-6 wow fadeInUp'
                  data-wow-delay='0.2s'
                >
                  <div className='card-style-four mb-50 lg-mb-40'>
                    <div className='media d-flex align-items-center justify-content-center position-relative overflow-hidden'>
                      <img
                        src='assets/image/team/team3.png'
                        data-src='assets/image/team/team3.png'
                        alt='team'
                        className='lazy-img w-100'
                      />
                    </div>
                    <h4 className='fw-500 pt-20 m0'>Dr. Farooq Abdal</h4>
                    <div className='fs-6'>Founder & Managing Director</div>
                    <a
                      href='/team-details'
                      className='text-decoration-underline mt-2'
                    >
                      Read More...
                    </a>
                  </div>
                </div>
                <div className='col-lg-3 col-sm-6 wow fadeInUp'>
                  <div className='card-style-four mb-50 lg-mb-40'>
                    <div className='media d-flex align-items-center justify-content-center position-relative overflow-hidden'>
                      <img
                        src='assets/image/team/team1.png'
                        data-src='assets/image/team/team1.png'
                        alt='team'
                        className='lazy-img w-100'
                      />
                    </div>
                    <h4 className='fw-500 pt-20 m0'>
                      Mr. Abdul Hussain Jaffar
                    </h4>
                    <div className='fs-6'>Partner</div>
                    <a
                      href='/team-details'
                      className='text-decoration-underline mt-2'
                    >
                      Read More...
                    </a>
                  </div>
                </div>
                <div
                  className='col-lg-3 col-sm-6 wow fadeInUp'
                  data-wow-delay='0.1s'
                >
                  <div className='card-style-four mb-50 lg-mb-40'>
                    <div className='media d-flex align-items-center justify-content-center position-relative overflow-hidden'>
                      <img
                        src='assets/image/team/team2.png'
                        data-src='assets/image/team/team2.png'
                        alt='team'
                        className='lazy-img w-100'
                      />
                    </div>
                    <h4 className='fw-500 pt-20 m0'>Mr. Ali Taqi Al Lawati</h4>
                    <div className='fs-6'>Business Development Manager</div>
                    <a
                      href='/team-details'
                      className='text-decoration-underline mt-2'
                    >
                      Read More...
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Team />

        <Footer />
      </div>
    </>
  );
}