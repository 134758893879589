import React, { useState } from 'react';
import { EMAIL_PATTERN } from '../constants';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function Footer() {
  const [formData, setFormData] = useState({ email: '' });

  const [validation, setValidation] = useState({ email: false });
  const [errorMsg, setErrorMsg] = useState('');
  const [show, setShow] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValidation({ ...validation, [name]: false });
    setErrorMsg('');
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const optionalFields = new Set([]);
      for (const key in formData) {
        if (optionalFields.has(key)) {
          continue;
        }
        if (!formData[key]) {
          setValidation({ ...validation, [key]: true });
          return;
        }
        if (key === 'email' && !EMAIL_PATTERN.test(formData[key])) {
          setValidation({ ...validation, email: true });
          return;
        }
      }
      const response = await axios.post(
        'https://api.espmining.io/api/v1/server/newsLetter',
        { email: formData.email }
      );
      console.log(response, 'responsssss');
      if (response.data.status === 'success') {
        setShow(true);
      } else {
        setErrorMsg(response.data.msg);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <div className='footer-one'>
        <div className='container'>
          <div className='inner-wrapper'>
            <div className='row justify-content-between'>
              <div className='col-xl-4 col-md-3 footer-intro mb-30'>
                <div className='logo mb-15'>
                  <a href='/' className='d-inline-block d-lg-none'>
                    <img src='/assets/image/logo/logo.svg' alt='logo' />
                  </a>
                </div>
                <p className='text-white lh-sm mb-35'>
                  BST Group
                  <span className='opacity-50 ms-1'>
                    is making life better with innovative solutions in various
                    Web3 sectors worldwide.
                  </span>
                </p>
                <ul className='style-none d-flex align-items-center social-icon'>
                  <li>
                    <a href='https://twitter.com/Bstgroupworld' target='_blank'>
                      <i className='bi bi-twitter' />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.facebook.com/BstworldUAE'
                      target='_blank'
                    >
                      <i className='bi bi-facebook' />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.instagram.com/biosapphiretrading/'
                      target='_blank'
                    >
                      <i className='bi bi-instagram' />
                    </a>
                  </li>
                  <li>
                    <a href='https://youtube.com/@bstgroup' target='_blank'>
                      <i className='bi bi-youtube' />
                    </a>
                  </li>
                  <li>
                    <a href='https://discord.gg/hCHr5YMQ' target='_blank'>
                      <i className='bi bi-discord' />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.reddit.com/r/BST_group/'
                      target='_blank'
                    >
                      <i className='bi bi-reddit' />
                    </a>
                  </li>
                </ul>
                <ul className='style-none d-flex align-items-center social-icon social-icon2 mt-3'>
                  <li>
                    <a href='#' target='_blank'>
                      <img
                        src='/assets/image/icon/play_store.svg'
                        alt='play store'
                      />
                    </a>
                  </li>
                  <li>
                    <a href='#' target='_blank'>
                      <img
                        src='/assets/image/icon/app_store.svg'
                        alt='app store'
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div className='col-xl-2 col-md-3 col-sm-4 mb-20'>
                <h5 className='footer-title'>Ecosystem</h5>
                <ul className='footer-nav-link style-none'>
                  <li>
                    <a href='/bst-bank'>BST Bank</a>
                  </li>
                  <li>
                    <a href='/sapphex'>SappheX</a>
                  </li>
                  <li>
                    <a href='/bstravel'>BSTravel</a>
                  </li>
                  <li>
                    <a href='/blue-fashion'>Blue Fashion</a>
                  </li>
                  <li>
                    <a href='/bst-metaverse'>BST Metaverse</a>
                  </li>
                  <li>
                    <a href='/bst-games'>BST Games</a>
                  </li>
                  <li>
                    <a href='/bst-management'>Assets and Wealth</a>
                  </li>
                </ul>
              </div>
              <div className='col-xl-2 col-md-3 col-sm-4 mb-20'>
                <h5 className='footer-title'>Company</h5>
                <ul className='footer-nav-link style-none'>
                  <li>
                    <a href='/about-us'>About us</a>
                  </li>
                  <li>
                    <a href='/gallery'>Gallery</a>
                  </li>
                  <li>
                    <a href='/career'>Careers</a>
                  </li>
                  <li>
                    <a href='/team-details'>Team</a>
                  </li>
                  <li>
                    <a href='/news-list'>News</a>
                  </li>
                  <li>
                    <a href='/roadmap'>Road Map</a>
                  </li>
                  <li>
                    <a href='https://bstchain.io/' target='_blank'>
                      BST Chain
                    </a>
                  </li>
                </ul>
              </div>
              <div className='col-xl-2 col-md-3 col-sm-4 mb-20'>
                <h5 className='footer-title'>Support</h5>
                <ul className='mb-0'>
                  <li>
                    <h6 className='footer-title footer-sub-title'>Legal</h6>
                  </li>
                  <ul className='footer-nav-link style-none'>
                    <li>
                      <a
                        href='assets/image/whitepaper/WHITEPAPER_VERSION_2.0.pdf'
                        target='_blank'
                      >
                        WhitePaper
                      </a>
                    </li>
                  </ul>
                </ul>
                <ul className='footer-nav-link style-none'>
                  <li>
                    <a href='/contact-us'>Contact us</a>
                  </li>
                  <li>
                    <a href='/faqs'>FAQ’s</a>
                  </li>
                  <li>
                    <a href='#'>Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='bottom-footer'>
            <div className='row align-items-center'>
              <div className='col-lg-5 order-lg-last mb-15'>
                <div className='footer-newsletter float-xl-end'>
                  <h5 className='footer-title'>Subscribe Newsletter</h5>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <input
                      type='email'
                      className='form-control'
                      placeholder='Enter your email address'
                      name='email'
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {validation.email && (
                      <div>
                        {formData.email
                          ? 'Invalid email'
                          : 'Please enter email'}
                      </div>
                    )}
                    {errorMsg && <div>{errorMsg}</div>}
                    <button onClick={() => handleSubmit()}>
                      <i className='bi bi-arrow-right' />
                    </button>
                  </form>
                </div>
              </div>
              <div className='col-lg-7 order-lg-first mb-15'>
                <a href='/' className='d-none d-lg-inline-block mb-25'>
                  <img src='/assets/image/logo/logo.svg' alt='logo' />
                </a>
                <div className='d-xl-flex align-items-center'>
                  <div className='copyright me-xl-4 lg-mt-10 order-lg-first'>
                    Copyright @2024 BST Group.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <h5 className='mb-0 text-center'>News letter subscribed successfully!</h5>
        </Modal.Body>
        <Modal.Footer className='justify-content-center'>
          <Button className='close_btn' onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}