import { Route, Routes } from 'react-router-dom';
import ContactUs from './pages/ContactUs';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
// import Blog from './pages/Blog';
// import BlogMarket from './pages/BlogMraket';
import BSTBank from './pages/BSTBank';
import BstcFashion from './pages/BstcFashion';
import BstcTravel from './pages/BstcTravel';
import BstcMetaverse from './pages/BstcMetaverse';
import BstcGame from './pages/BstcGame';
import BstcManagement from './pages/BstcManagement';
import BstcSafex from './pages/BstcSafex';
import Career from './pages/Career';
import Roadmap from './pages/Roadmap';
import NewsList from './pages/NewsList';
import Faqs from './pages/Faqs';
import BstTeam from './pages/BstTeam';
import Gallery from './pages/Gallery';


function App() {

  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/contact-us' element={<ContactUs />} />
      <Route path='/about-us' element={<AboutUs />} />
      <Route path='/career' element={<Career />} />
      <Route path='/roadmap' element={<Roadmap />} />
      <Route path='/news-list' element={<NewsList />} />
      <Route path='/faqs' element={<Faqs />} />
      <Route path='/team-details' element={<BstTeam />} />
      <Route path='/gallery' element={<Gallery />} />
    
      {/* <Route path='/news/BST-Group-Reigns-Supreme' element={<Blog />} />
      <Route path='/news/The-BST-Group-Announces' element={<BlogMarket />} /> */}

      <Route path='/bst-bank' element={<BSTBank />} />
      <Route path='/blue-fashion' element={<BstcFashion />} />
      <Route path='/bstravel' element={<BstcTravel />} />
      <Route path='/bst-metaverse' element={<BstcMetaverse />} />
      <Route path='/bst-games' element={<BstcGame />} />
      <Route path='/bst-management' element={<BstcManagement />} />
      <Route path='/sapphex' element={<BstcSafex />} />
    </Routes>
  );
}

export default App;