import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'

export default function NewsList() {

    const newsArray = [
        { logo: 'news1.png', url: 'https://www.bihartimes.news/news/bst-group-launches-the-worlds-first-asset-backed-cryptoeco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news4.png', url: 'https://jodhpurreporter.com/index.php/2024/06/03/bst-group-launches-the-worlds-first-assetbacked-crypto-eco-system-for-investors-to-benefit-from-real-assets/' },
        { logo: 'news2.png', url: 'https://rajasthanexpress.in/index.php/2024/06/03/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets/' },
        { logo: 'news3.png', url: 'https://up18news.com/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets/' },
        { logo: 'news5.png', url: 'http://m.dailyhunt.in/news/india/english/ani+english-epaper-anieng/bst+group+launches+theworlds+first+assetbacked+crypto+ecosystem+for+investors+to+benefit+from+real+assetsnewsid-n614185884?sm=Y' },
        { logo: 'news13.png', url: 'https://www.madhyapradeshchronicle.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news6.png', url: 'https://delhinewswatch.com/index.php/2024/06/03/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets/' },
        { logo: 'news7.png', url: 'https://www.gujaratvarta.com/news/bst-group-launches-the-worlds-first-asset-backed-cryptoeco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news8.png', url: 'https://www.gujaratsamachar.news/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news14.png', url: 'https://www.chhattisgarhtoday.in/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news9.png', url: 'https://www.dailyprabhat.com/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets/' },
        { logo: 'news15.png', url: 'https://www.himachalpatrika.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news10.png', url: 'https://www.punjablive.news/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news11.png', url: 'https://northwestnewstimes.com/index.php/2024/06/03/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets/' },
        { logo: 'news12.png', url: 'https://www.odishapost.news/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news16.png', url: 'https://allahabadpost.in/business/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets/' },
        { logo: 'news18.png', url: 'https://www.mountainviewsentinel.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news17.png', url: 'https://www.eastcoastamericannews.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news19.png', url: 'https://www.andhrapradeshmirror.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news20.png', url: 'https://www.karnatakalive.in/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news22.png', url: 'https://www.haryanatoday.in/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news21.png', url: 'https://livemumbai.in/index.php/2024/06/03/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets/' },
        { logo: 'news23.png', url: 'https://arabiantimesonline.com/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets/' },
        { logo: 'news24.png', url: 'https://www.business-standard.com/amp/content/press-releases-ani/bst-group-launches-the-world-s-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets-124060300670_1.html' },
        { logo: 'news25.png', url: 'https://www.aninews.in/news/business/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154713/#' },
        { logo: 'news38.png', url: 'https://theprint.in/ani-press-releases/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets/2113934/?amp' },
        { logo: 'news26.png', url: 'https://palgharnews.com/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets-world-news-network/' },
        { logo: 'news27.png', url: 'https://www.worldnewsnetwork.net/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news28.png', url: 'https://www.msn.com/en-xl/news/other/bst-group-launches-the-world-s-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets/ar-BB1nxghe' },
        { logo: 'news31.png', url: 'https://www.maldivesstarplus.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news29.png', url: 'https://www.britishnewsnetwork.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news30.png', url: 'https://www.britishcolumbiatimes.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news32.png', url: 'https://www.srilankaislandnews.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news33.png', url: 'https://www.kashmirbreakingnews.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news34.png', url: 'https://www.europeansuntimes.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news35.png', url: 'https://www.latestly.com/agency-news/business-news-bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets-6009628.html' },
        { logo: 'news36.png', url: 'https://www.indianeconomicobserver.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news37.png', url: 'https://www.floridabreakingnews.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news39.png', url: 'https://www.francenetworktimes.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news40.png', url: 'https://www.torontosuntimes.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news41.png', url: 'https://www.englandnewsportal.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news42.png', url: 'https://www.dubaicityreporter.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news43.png', url: 'https://www.newyorkdespatch.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news44.png', url: 'https://www.whitehousenewstime.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news45.png', url: 'https://www.eastasiaallnewsportal.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news46.png', url: 'https://www.londonchannelnews.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news47.png', url: 'https://www.miaminewsherald.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news48.png', url: 'https://www.federaldespatch.com/news/bst-group-launches-the-worlds-first-asset-backed-crypto-eco-system-for-investors-to-benefit-from-real-assets20240603154709/' },
        { logo: 'news49.png', url: 'https://news.abplive.com/brand-wire/bst-group-unveils-revolutionary-asset-backed-crypto-ecosystem-1693789' }
    ];

    return (
        <>
            <Helmet>
                <title>BST Group News - Explore Insights and Updates</title>
                <meta name="title" content="BST Group News - Explore Insights and Updates" />
                <meta name="description" content="Explore the latest insights, updates, and news from BST Group on our news. Stay informed about industry trends and developments." />
                <meta name="keywords" content="Insights, Updates, News, Industry Trends, Developments, Blog, Articles, Information, Knowledge, Analysis, Commentary, Trends, Technology, Innovation, Financial, Empowerment, Virtual Reality, Immersive Experiences, Digital Landscapes, Collaboration, Explore, BST Group, BST chain, Blockchain, Cryptocurrency, Digital Transformation, Smart Contracts, Decentralized Finance, NFTs, Tokenization" />
                <link rel="canonical" href="https://bstgroup.world/news-list" />
            </Helmet>

            <Header />

            <div className='main-page-wrapper'>

                <div
                    className="inner-banner-one pt-225 lg-pt-200 md-pt-150 pb-100 md-pb-70 position-relative"
                    style={{ backgroundImage: "url(../assets/image/about/contact.png)" }}
                >
                    <div className="container position-relative">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <h1 className="hero-heading d-inline-block text-white position-relative">
                                    Explore our News
                                </h1>
                            </div>
                            <div className="col-xl-4 col-lg-5 ms-auto">
                                <p className="text-white text-lg mb-70 lg-mb-40">
                                    Discover the latest news and updates regarding the BST Group.
                                </p>
                                {/* <ul className="style-none d-inline-flex pager">
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>/</li>
                                    <li>Contact</li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="blog-section-two position-relative mt-150 lg-mt-80 mb-150 lg-mb-80">
                    <div className="container">
                        <div className="position-relative">
                            <div className="row gx-xxl-5">
                                <div className="col-md-6">
                                    <article className="blog-meta-two mb-80 lg-mb-50 wow fadeInUp">
                                        <figure className="post-img p-0 rounded-5 position-relative d-flex align-items-end m0" style={{ backgroundImage: 'url(assets/image/news/news1.png)', border: '1px solid #022346' }}>
                                        </figure>
                                        <div className="post-data">
                                            <div className="d-flex justify-content-between flex-wrap">
                                                <a href="https://arabiantimesonline.com/bst-group-bridging-real-world-assets-with-web3/" target='_blank' className="blog-title">
                                                    <h4>BST Group: Bridging Real World Assets with Web3</h4>
                                                </a>
                                                <a href="https://arabiantimesonline.com/bst-group-bridging-real-world-assets-with-web3/" target='_blank' className="round-btn rounded-circle d-flex align-items-center justify-content-center tran3s">
                                                    <i className="bi bi-arrow-up-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6">
                                    <article className="blog-meta-two mb-80 lg-mb-50 wow fadeInUp" data-wow-delay="0.1s">
                                        <figure className="post-img p-0 rounded-5 position-relative d-flex align-items-end m0" style={{ backgroundImage: 'url(assets/image/news/news2.png)', border: '1px solid #022346' }}>
                                        </figure>
                                        <div className="post-data">
                                            <div className="d-flex justify-content-between flex-wrap">
                                                <a href="https://arabiantimesonline.com/dr-abdals-vision-empowering-smart-cities-and-employment-through-e-sapphire-mining/" target='_blank' className="blog-title">
                                                    <h4>DR. ABDAL’S VISION: Empowering smart cities and employment through E-Sapphire mining</h4>
                                                </a>
                                                <a href="https://arabiantimesonline.com/dr-abdals-vision-empowering-smart-cities-and-employment-through-e-sapphire-mining/" target='_blank' className="round-btn rounded-circle d-flex align-items-center justify-content-center tran3s">
                                                    <i className="bi bi-arrow-up-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6">
                                    <article className="blog-meta-two mb-80 lg-mb-50 wow fadeInUp">
                                        <figure className="post-img p-0 rounded-5 position-relative d-flex align-items-end m0" style={{ backgroundImage: 'url(assets/image/news/news3.png)', border: '1px solid #022346' }}>
                                        </figure>
                                        <div className="post-data">
                                            <div className="d-flex justify-content-between flex-wrap">
                                                <a href="https://emiratess.live/News_more.php?page=dubai-ghana-south-korea-cochin-noida-calcutta-coimbatore" target='_blank' className="blog-title">
                                                    <h4>DUBAI-GHANA-SOUTH KOREA-COCHIN-NOIDA-CALCUTTA-COIMBATORE</h4>
                                                </a>
                                                <a href="https://emiratess.live/News_more.php?page=dubai-ghana-south-korea-cochin-noida-calcutta-coimbatore" target='_blank' className="round-btn rounded-circle d-flex align-items-center justify-content-center tran3s">
                                                    <i className="bi bi-arrow-up-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6">
                                    <article className="blog-meta-two mb-80 lg-mb-50 wow fadeInUp" data-wow-delay="0.1s">
                                        <figure className="post-img p-0 rounded-5 position-relative d-flex align-items-end m0" style={{ backgroundImage: 'url(assets/image/news/news4.png)', border: '1px solid #022346' }}>
                                        </figure>
                                        <div className="post-data">
                                            <div className="d-flex justify-content-between flex-wrap">
                                                <a href="https://www.youtube.com/watch?v=p3CFJ2O8-2o" target='_blank' className="blog-title">
                                                    <h4>European Digital University is ready to organize World Meta Summits across the world</h4>
                                                </a>
                                                <a href="https://www.youtube.com/watch?v=p3CFJ2O8-2o" target='_blank' className="round-btn rounded-circle d-flex align-items-center justify-content-center tran3s">
                                                    <i className="bi bi-arrow-up-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6">
                                    <article className="blog-meta-two mb-80 lg-mb-50 wow fadeInUp">
                                        <figure className="post-img p-0 rounded-5 position-relative d-flex align-items-end m0" style={{ backgroundImage: 'url(assets/image/news/news5.png)', border: '1px solid #022346' }}>
                                        </figure>
                                        <div className="post-data">
                                            <div className="d-flex justify-content-between flex-wrap">
                                                <a href="https://www.youtube.com/watch?v=uXwLn7u1BmU" target="_blank" className="blog-title">
                                                    <h4>Dr. Farooq Abdal, killopaedia Health & Wellness fair, 7th march, Dubai, on WeTel TV UAE</h4>
                                                </a>
                                                <a href="https://www.youtube.com/watch?v=uXwLn7u1BmU" target="_blank" className="round-btn rounded-circle d-flex align-items-center justify-content-center tran3s">
                                                    <i className="bi bi-arrow-up-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6">
                                    <article className="blog-meta-two mb-80 lg-mb-50 wow fadeInUp" data-wow-delay="0.1s">
                                        <figure className="post-img p-0 rounded-5 position-relative d-flex align-items-end m0" style={{ backgroundImage: 'url(assets/image/news/news6.png)', border: '1px solid #022346' }}>
                                        </figure>
                                        <div className="post-data">
                                            <div className="d-flex justify-content-between flex-wrap">
                                                <a href="https://www.youtube.com/watch?v=B-JSLITCrJ4" target='_blank' className="blog-title">
                                                    <h4>Talk Show With Dr Faruq Abdal & Prof Sidhic A Muhammed On UBL TV With Muneer Al Wafaa</h4>
                                                </a>
                                                <a href="https://www.youtube.com/watch?v=B-JSLITCrJ4" target='_blank' className="round-btn rounded-circle d-flex align-items-center justify-content-center tran3s">
                                                    <i className="bi bi-arrow-up-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6">
                                    <article className="blog-meta-two mb-80 lg-mb-50 wow fadeInUp" data-wow-delay="0.1s">
                                        <figure className="post-img p-0 rounded-5 position-relative d-flex align-items-end m0" style={{ backgroundImage: 'url(assets/image/news/news7.png)', border: '1px solid #022346' }}>
                                        </figure>
                                        <div className="post-data">
                                            <div className="d-flex justify-content-between flex-wrap">
                                                <a href="https://finance.yahoo.com/news/bst-group-announces-groundbreaking-developments-114000888.html?guccounter=1" target="_blank" className="blog-title">
                                                    <h4>The BST Group Announces Groundbreaking Developments in the Global Crypto Market</h4>
                                                </a>
                                                <a href="https://finance.yahoo.com/news/bst-group-announces-groundbreaking-developments-114000888.html?guccounter=1" target="_blank" className="round-btn rounded-circle d-flex align-items-center justify-content-center tran3s">
                                                    <i className="bi bi-arrow-up-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6">
                                    <article className="blog-meta-two mb-80 lg-mb-50 wow fadeInUp" data-wow-delay="0.1s">
                                        <figure className="post-img p-0 rounded-5 position-relative d-flex align-items-end m0" style={{ backgroundImage: 'url(assets/image/news/news8.png)', border: '1px solid #022346' }}>
                                        </figure>
                                        <div className="post-data">
                                            <div className="d-flex justify-content-between flex-wrap">
                                                <a href="https://news.abplive.com/brand-wire/bst-group-unveils-revolutionary-asset-backed-crypto-ecosystem-1693789" target="_blank" className="blog-title">
                                                    <h4>BST Group Unveils Revolutionary Asset-Backed Crypto Ecosystem</h4>
                                                </a>
                                                <a href="https://news.abplive.com/brand-wire/bst-group-unveils-revolutionary-asset-backed-crypto-ecosystem-1693789" target="_blank" className="round-btn rounded-circle d-flex align-items-center justify-content-center tran3s">
                                                    <i className="bi bi-arrow-up-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6">
                                    <article className="blog-meta-two mb-80 lg-mb-50 wow fadeInUp" data-wow-delay="0.1s">
                                        <figure className="post-img p-0 rounded-5 position-relative d-flex align-items-end m0" style={{ backgroundImage: 'url(assets/image/news/news10.jpg)', border: '1px solid #022346' }}>
                                        </figure>
                                        <div className="post-data">
                                            <div className="d-flex justify-content-between flex-wrap">
                                                <a href="https://www.accesswire.com/848135/the-bst-group-announces-groundbreaking-developments-in-the-global-crypto-market" target="_blank" className="blog-title">
                                                    <h4>The BST Group Announces Groundbreaking Developments in the Global Crypto Market</h4>
                                                </a>
                                                <a href="https://www.accesswire.com/848135/the-bst-group-announces-groundbreaking-developments-in-the-global-crypto-market" target="_blank" className="round-btn rounded-circle d-flex align-items-center justify-content-center tran3s">
                                                    <i className="bi bi-arrow-up-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6">
                                    <article className="blog-meta-two mb-80 lg-mb-50 wow fadeInUp" data-wow-delay="0.1s">
                                        <figure className="post-img p-0 rounded-5 position-relative d-flex align-items-end m0" style={{ backgroundImage: 'url(assets/image/about/news02.png)', border: '1px solid #022346' }}>
                                        </figure>
                                        <div className="post-data">
                                            <div className="d-flex justify-content-between flex-wrap">
                                                <a href="https://www.dtnext.in/news/business/bst-group-reigns-supreme-unveiling-web-3-dominance-at-crypto-expo-dubai-2024-778299" target="_blank" className="blog-title">
                                                    <h4>BST Group Reigns Supreme: Unveiling Web 3 Dominance at Crypto Expo Dubai 2024</h4>
                                                </a>
                                                <a href="https://www.dtnext.in/news/business/bst-group-reigns-supreme-unveiling-web-3-dominance-at-crypto-expo-dubai-2024-778299" target="_blank" className="round-btn rounded-circle d-flex align-items-center justify-content-center tran3s">
                                                    <i className="bi bi-arrow-up-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div className='row align-items-center'>
                                {
                                    newsArray.map((i, index) =>
                                        <div className='col-lg-2 col-md-3 col-sm-4 col-6 news_logo' key={index}>
                                            <a href={i.url} target="_blank">
                                                <img
                                                    src={`/assets/image/news_logo/${i.logo}`}
                                                />
                                            </a>
                                        </div>
                                    )
                                }
                            </div>
                            {/* <div className="pagination-one mt-20">
                                <ul className="style-none d-flex align-items-center justify-content-center">
                                    <li><a href="#" className="active">1</a></li>
                                    <li><a href="#">2</a></li>
                                    <li><a href="#">3</a></li>
                                    <li><a href="#">4</a></li>
                                    <li>...</li>
                                    <li><a href="#">Last <i className="bi bi-arrow-right" /></a></li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
} 