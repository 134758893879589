import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Team from '../components/Team'
import { Helmet } from 'react-helmet'

export default function BstcFashion() {
    return (
        <>
            <Helmet>
                <title>Embracing Blockchain Trends in the Fashion Industry</title>
                <meta name="title" content="Embracing Blockchain Trends in the Fashion Industry" />
                <meta name="description" content="Explore how Blue Fashion integrates blockchain technology into the fashion industry, shaping trends and experiences." />
                <meta name="keywords" content="Fashion, Blue Fashion, Blockchain, Trends, Web3, Style, Innovation, Design, Virtual Fashion, Crypto Fashion, NFT Fashion, Digital Fashion, Smart Fashion, Fashion Revolution, Metaverse Fashion, Fashion Technology, Fashion Innovation, BST Fashion, Fashion Industry, Crypto Trends, Web3 Fashion, Fashion Metaverse, Blockchain Fashion, Fashion Revolution, Digital Trends, BST Trends" />
                <link rel="canonical" href="https://bstgroup.world/blue-fashion" />
            </Helmet>

            <Header />

            <div className='main-page-wrapper'>
                <div
                    className="inner-banner-one pt-225 lg-pt-200 md-pt-150 pb-100 md-pb-70 position-relative"
                    style={{ backgroundImage: "url(../assets/image/srvices/fashion.jpg)" }}
                >
                    <div className="container position-relative">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <h1 className="hero-heading text-white d-inline-block position-relative me-xl-4">
                                    BLUE FASHION{" "}
                                </h1>
                            </div>
                            <div className="col-xl-4 col-lg-5 ms-auto">
                                <p className="text-white text-lg mb-70 lg-mb-40">
                                    Witness the future of fashion with blockchain-integrated designs and concepts.
                                </p>
                                <ul className="style-none d-inline-flex pager">
                                    <li>
                                        <a href="#">Explore Now</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-details mt-150 lg-mt-80 mb-100 lg-mb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 order-lg-last">
                                <div className="details-meta ps-xxl-5 ps-xl-3">
                                    <h2>Blockchain Couture and Fashion Redefined Through Web3 Innovation</h2>
                                    <p>
                                        At Blue Fashion, innovation and style converge to redefine the fashion landscape. Our avant-garde collections, curated by visionary designers, push the boundaries of traditional fashion, setting new trends in the Web3 era. By embracing technology, we fuse digital platforms, virtual reality, and augmented reality to enhance the shopping experience, catering to modern tastes while maintaining timeless elegance.{" "}
                                    </p>
                                    <p>
                                        Discover the forefront of fashion evolution with Blue Fashion Magazine, where enthusiasts explore the latest trends, industry insights, and style inspirations. From cutting-edge designs to modern tastes, we cater to every occasion and personality, ensuring our clients stand out as trendsetters in the dynamic fashion world.
                                    </p>
                                    <h3 className='mt-4'>Eco Fashion</h3>
                                    <p>
                                        Embracing sustainability, Blue Fashion prioritizes eco-friendly materials and practices, advocating for responsible fashion production and environmental stewardship.
                                    </p>
                                    <div className="line-wrapper pb-60 mt-60 lg-mt-40">
                                        <div className="row">
                                            <div className="col-md-4 wow fadeInUp">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_81.svg"
                                                            data-src="assets/image/icon_81.svg"
                                                            alt="Sustainable Fashion"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">
                                                        Sustainable Fashion
                                                    </h4>
                                                    <p className="m0">
                                                        Eco-friendly materials and practices for responsible fashion production.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 wow fadeInUp" data-wow-delay="0.1s">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_82.svg"
                                                            data-src="assets/image/icon/icon_82.svg"
                                                            alt="Design Collaborations"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">
                                                        Design Collaborations
                                                    </h4>
                                                    <p className="m0">
                                                        Partnerships with emerging talents for innovative fashion creations.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 wow fadeInUp" data-wow-delay="0.2s">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_83.svg"
                                                            data-src="assets/image/icon/icon_83.svg"
                                                            alt="Blockchain Tech"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">Blockchain Tech</h4>
                                                    <p className="m0">
                                                        Transparency and authentication through blockchain integration for product traceability.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Team />
                <Footer />
            </div >
        </>
    )
}