import React from 'react'

export default function Team() {
    return (
        <>
            <div className="fancy-banner-two mt-150 xl-mt-120 lg-mt-80 mb-150 xl-mb-120 lg-mb-60">
                <div className="container">
                    <div className="text-center wow fadeInUp">
                        <div className="row">
                            <div className="col-lg-10 m-auto">
                                <div className="title-two">
                                    <h2>Need to chat? Don't hesitate to reach out to our team.</h2>
                                </div>
                                <p className="text-lg mt-40 lg-mt-30 mb-30">
                                    "Questions? Reach out to our helpful team for assistance!"
                                </p>
                                <div className="d-inline-flex flex-wrap justify-content-center">
                                    <a href="/contact-us" className="btn-six mt-10 ms-2">
                                        Contact
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}