import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Team from '../components/Team'
import { Helmet } from 'react-helmet'

export default function Faqs() {
    return (
        <>
            <Helmet>
                <title>FAQs | BST Group | Answers About BSTC Bank, Fashion, Crypto Exchange & More</title>
                <meta name="title" content="FAQs | BST Group | Answers About BSTC Bank, Fashion, Crypto Exchange & More" />
                <meta name="description" content="Discover answers to frequently asked questions about BST Group's offerings, including BSTC Bank services, fashion trends by BSTC Fashion, digital asset trading on the BSTC Crypto Exchange, travel services provided by BSTC Travel, and insights into the innovative BSTC Metaverse." />
                <meta name="keywords" content="BST Group FAQs, BSTC Bank services, BSTC Fashion trends, BSTC Crypto Exchange assets, BSTC Travel services, BSTC Metaverse details, BST Group portfolio, Web3 industries, Digital assets trading, BST Chain ecosystem, SappheX platform, Blue Fashion updates, BST Metaverse features, BST Games information, BST Group news, BST Bank loans, BSTravel solutions, BST Metaverse virtual reality, BST Group careers, BST Chain support, BST Group legal info, BST Group WhitePaper, Contact BST Group, Privacy Policy BST Group, Subscribe to BST Group newsletter" />
                <link rel="canonical" href="https://bstgroup.world/faqs" />
            </Helmet>
            <Header />

            <div className='main-page-wrapper light-bg'>

                <div
                    className="inner-banner-one pt-225 lg-pt-200 md-pt-150 pb-100 md-pb-70 position-relative"
                    style={{ backgroundImage: "url(../assets/image/about/contact.png)" }}
                >
                    <div className="container position-relative">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <h1 className="hero-heading text-white d-inline-block position-relative me-xl-4">
                                    Questions & Answers{" "}
                                </h1>
                            </div>
                            <div className="col-xl-4 col-lg-5 ms-auto">
                                <p className="text-white text-lg mb-70 lg-mb-40">
                                    Don’t find the answer? We can help.
                                </p>
                                {/* <ul className="style-none d-inline-flex pager">
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>/</li>
                                    <li>BSTravel</li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-details mt-150 lg-mt-80 mb-100 lg-mb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 order-lg-last">
                                <div className='faq-section-three'>
                                    <div className='tab-content'>
                                        <div className="tab-pane fade show active">
                                            <div className="accordion accordion-style-one" id="accordionOne">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                            What is BST Group and its industries?
                                                        </button>
                                                    </h2>
                                                    <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionOne">
                                                        <div className="accordion-body">
                                                            <p>
                                                                BST Group innovates across finance, fashion, travel, virtual reality, gaming, and wealth management sectors.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                            What services does BSTC Bank offer?
                                                        </button>
                                                    </h2>
                                                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionOne">
                                                        <div className="accordion-body">
                                                            <p>
                                                                BSTC Bank provides accounts, loans, investments, and intuitive digital banking platforms.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                                            How does BSTC Fashion stand out?
                                                        </button>
                                                    </h2>
                                                    <div id="collapseThree" className="accordion-collapse collapse show" data-bs-parent="#accordionOne">
                                                        <div className="accordion-body">
                                                            <p>
                                                                BSTC Fashion offers avant-garde designs, blends tech with fashion, and publishes a trendsetting magazine.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                            What digital assets can I trade on the BSTC Crypto Exchange?
                                                        </button>
                                                    </h2>
                                                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionOne">
                                                        <div className="accordion-body">
                                                            <p>
                                                                BSTC Crypto Exchange supports popular cryptocurrencies like Bitcoin, Ethereum, and Ripple, along with emerging altcoins.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                            What travel services does BSTC Travel provide?
                                                        </button>
                                                    </h2>
                                                    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionOne">
                                                        <div className="accordion-body">
                                                            <p>
                                                                BSTC Travel offers hassle-free booking for flights, hotels, vacation packages, and personalized experiences.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                            How does the BSTC Metaverse redefine virtual reality?
                                                        </button>
                                                    </h2>
                                                    <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionOne">
                                                        <div className="accordion-body">
                                                            <p>
                                                                The BSTC Metaverse provides immersive experiences, digital landscapes, creativity, and exploration beyond physical boundaries.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Team />

                <Footer />
            </div >
        </>
    )
}