import React from 'react'
import { SwiperSlide, Swiper } from "swiper/react";
import 'swiper/css';
import 'swiper/css/free-mode';
import { FreeMode, Mousewheel } from 'swiper/modules';

export default function Payments() {

    return (
        <>
            <Swiper
                direction={'horizontal'}
                slidesPerView={2.5}
                spaceBetween={40}
                mousewheel={true}
                freeMode={true}
                modules={[FreeMode, Mousewheel]}
                breakpoints={{
                    345: {
                        slidesPerView: 1.1,
                        spaceBetween: 10,
                    },
                    400: {
                        slidesPerView: 1.2,
                        spaceBetween: 15,
                    },
                    576: {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                    },
                    620: {
                        slidesPerView: 1.5,
                        spaceBetween: 25,
                    },
                    820: {
                        slidesPerView: 1.6,
                        spaceBetween: 25,
                    },
                    992: {
                        slidesPerView: 2.1,
                        spaceBetween: 30,
                    },
                    1200: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    1400: {
                        slidesPerView: 2.1,
                        spaceBetween: 30,
                    },
                    1500: {
                        slidesPerView: 2.1,
                    },
                    1880: {
                        slidesPerView: 2,
                    },
                }}
                className='mySwiper'
            >
                <SwiperSlide>
                    <div className='payment_card'>
                        <div className='slide_icon'>
                            <img src='assets/image/icon/bank.svg' alt='bank' />
                        </div>
                        <div className=''>
                            <h3 className='card_title'>BST Chain</h3>
                            <p className='text-white text-16 fw-400 mb-0'>Scalable, secure, sustainable, high-throughput blockchain for decentralized applications.</p>
                            <div className='slide_link'>
                                <a href='https://bstchain.io/' target='_blank' className='d-flex align-items-center gap-2'><span>Explore Now</span> <img src='assets/image/icon/arrow-right.svg' alt='arrow-right' /></a>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='payment_card'>
                        <div className='slide_icon'>
                            <img src='assets/image/icon/explorer.svg' alt='candle' />
                        </div>
                        <div className=''>
                            <h3 className='card_title'>BST Explorer</h3>
                            <p className='text-white text-16 fw-400 mb-0'>Assists in analyzing Sapphire blockchain data for insights.</p>
                            <div className='slide_link'>
                                <a href='https://bstscan.com/' target='_blank' className='d-flex align-items-center gap-2'><span>Explore Now</span> <img src='assets/image/icon/arrow-right.svg' alt='arrow-right' /></a>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='payment_card'>
                        <div className='slide_icon'>
                            <img src='assets/image/icon/bracelet.svg' alt='bracelet' />
                        </div>
                        <div className=''>
                            <h3 className='card_title'>BioSapphire Bracelet</h3>
                            <p className='text-white text-16 fw-400 mb-0'>Bracelet promotes wellness and offers aesthetic elegance via Blue Sapphire.</p>
                            <div className='slide_link'>
                                <a href='https://bsaft.com/product/bracelet/?bst=990131' target='_blank' className='d-flex align-items-center gap-2'><span>Explore Now</span> <img src='assets/image/icon/arrow-right.svg' alt='arrow-right' /></a>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='payment_card'>
                        <div className='slide_icon'>
                            <img src='assets/image/icon/candle.svg' alt='candle' />
                        </div>
                        <div className=''>
                            <h3 className='card_title'>Sapphire Trading</h3>
                            <p className='text-white text-16 fw-400 mb-0'>We innovates crypto assets with real-world value integrity.</p>
                            <div className='slide_link'>
                                <a href='https://sapphirechain.group/' target='_blank' className='d-flex align-items-center gap-2'><span>Explore Now</span> <img src='assets/image/icon/arrow-right.svg' alt='arrow-right' /> </a>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='payment_card'>
                        <div className='slide_icon'>
                            <img src='assets/image/icon/sapphire.svg' alt='sapphire' />
                        </div>
                        <div className=''>
                            <h3 className='card_title'>E-Sapphire</h3>
                            <p className='text-white text-16 fw-400 mb-0'>Invest in Blue Sapphire, track investments, referrals, commissions, and earnings.</p>
                            <div className='slide_link'>
                                <a href='https://espmining.io/dashboard' target='_blank' className='d-flex align-items-center gap-2'><span>Explore Now</span> <img src='assets/image/icon/arrow-right.svg' alt='arrow-right' /> </a>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='payment_card'>
                        <div className='slide_icon'>
                            <img src='assets/image/icon/cricket.svg' alt='cricket' />
                        </div>
                        <div className=''>
                            <h3 className='card_title'>BST Cricket Gamefi</h3>
                            <p className='text-white text-16 fw-400 mb-0'>Offers a thrilling P2E cricket experience with rewards and NFTs.</p>
                            <div className='slide_link'>
                                <a href='https://shorturl.at/hmHK8' target='_blank' className='d-flex align-items-center gap-2'><span>Explore Now</span> <img src='assets/image/icon/arrow-right.svg' alt='arrow-right' /> </a>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='payment_card'>
                        <div className='slide_icon'>
                            <img src='assets/image/icon/chat.svg' alt='chat' />
                        </div>
                        <div className=''>
                            <h3 className='card_title'>BST Chat W3</h3>
                            <p className='text-white text-16 fw-400 mb-0'>An innovative blockchain messaging app connecting you globally with purpose</p>
                            <div className='slide_link'>
                                <a href='https://play.google.com/store/apps/details?id=com.bstchat' target='_blank' className='d-flex align-items-center gap-2'><span>Explore Now</span> <img src='assets/image/icon/arrow-right.svg' alt='arrow-right' /> </a>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    )
}