import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Team from '../components/Team'
import { Helmet } from 'react-helmet'

export default function BstcSafex() {
    return (
        <>
            <Helmet>
                <title>SappheX: Seamless Trading Solutions for the Web3 Era</title>
                <meta name="title" content="SappheX: Seamless Trading Solutions for the Web3 Era" />
                <meta name="description" content="Experience seamless trading in the Web3 era with SappheX, offering advanced solutions for digital asset investors and traders." />
                <meta name="keywords" content="Crypto, Exchange, SappheX, BST Exchange, Trading, Web3, Blockchain, Cryptocurrency, Innovation, Finance, Digital Assets, Crypto Trading, NFT Exchange, Web3 Exchange, Trading Platform, Crypto Technology, BST Exchange, Crypto Revolution, Financial Services, Blockchain Trading, Digital Trading, Crypto Innovation, Web3 Finance, BST Trends, Financial Trends" />
                <link rel="canonical" href="https://bstgroup.world/sapphex" />
            </Helmet>
            <Header />

            <div className='main-page-wrapper'>

                <div
                    className="inner-banner-one pt-225 lg-pt-200 md-pt-150 pb-100 md-pb-70 position-relative"
                    style={{ backgroundImage: "url(../assets/image/srvices/safex.jpg)" }}
                >
                    <div className="container position-relative">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <h1 className="hero-heading text-white d-inline-block position-relative me-xl-4">
                                    SappheX{" "}
                                </h1>
                            </div>
                            <div className="col-xl-4 col-lg-5 ms-auto">
                                <p className="text-white text-lg mb-70 lg-mb-40">
                                    The SappheX offers secure trading and diverse assets for seamless digital transactions.
                                </p>
                                <ul className="style-none d-inline-flex pager">
                                    <li>
                                        <a href="#">Explore Now</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-details mt-150 lg-mt-80 mb-100 lg-mb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 order-lg-last">
                                <div className="details-meta ps-xxl-5 ps-xl-3">
                                    <h2>Explore our platform's security, user-friendly interface, and diverse assets</h2>
                                    <p>
                                        SappheX facilitates secure and diverse digital asset trading. With robust security measures and a user-friendly interface, we empower traders to navigate the cryptocurrency market confidently.{" "}
                                    </p>
                                    <p>
                                        Discover seamless trading experiences with SappheX. Our platform offers stringent security, an intuitive interface, and a wide range of assets for your investment needs.
                                    </p>
                                    <h3 className='mt-4'>Digital Trading Hub</h3>
                                    <p>
                                        Secondary Description: SappheX offers a secure platform, diverse assets, and user-friendly interface for seamless digital trading experiences.
                                    </p>
                                    <div className="line-wrapper pb-60 mt-60 lg-mt-40">
                                        <div className="row">
                                            <div className="col-md-4 wow fadeInUp">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_81.svg"
                                                            data-src="assets/image/icon_81.svg"
                                                            alt="Advanced Tools"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">
                                                        Advanced Tools
                                                    </h4>
                                                    <p className="m0">
                                                        Access powerful features for enhanced trading and investment.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 wow fadeInUp" data-wow-delay="0.1s">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_82.svg"
                                                            data-src="assets/image/icon/icon_82.svg"
                                                            alt="High Security"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">
                                                        High Security
                                                    </h4>
                                                    <p className="m0">
                                                        Benefit from robust encryption and authentication for asset safety.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 wow fadeInUp" data-wow-delay="0.2s">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_83.svg"
                                                            data-src="assets/image/icon/icon_83.svg"
                                                            alt="Diverse Assets"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">Diverse Assets</h4>
                                                    <p className="m0">
                                                        Trade a wide range of cryptocurrencies and altcoins for varied investment options.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Team />

                <Footer />
            </div >
        </>
    )
}