import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { EMAIL_PATTERN } from "../constants";

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [validation, setValidation] = useState({
    name: false,
    email: false,
    message: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValidation({ ...validation, [name]: false });
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    try {
      const optionalFields = new Set([]);
      for (const key in formData) {
        if (optionalFields.has(key)) {
          continue;
        }
        if (!formData[key]) {
          setValidation({ ...validation, [key]: true });
          return;
        }
        if (key === "email" && !EMAIL_PATTERN.test(formData[key])) {
          setValidation({ ...validation, email: true });
          return;
        }
      }
    } catch (error) { }
  };

  return (
    <>
      <Helmet>
        <title>Contact BST Group - Get in Touch with Our Team Today</title>
        <meta
          name="title"
          content="Contact BST Group - Get in Touch with Our Team Today"
        />
        <meta
          name="description"
          content="Contact BST Group today to learn more about our products, services, and partnership opportunities. Our team is ready to assist you."
        />
        <meta
          name="keywords"
          content="Contact, Get in Touch, Partnership, Opportunities, Assistance, Support, Inquiry, Information, Team, Connect, Reach Out, Questions, Queries, Communication, Email, Phone, Address, Office, Collaboration, BST, BST Group, BST chain, Blockchain, Metaverse, Cryptocurrency, Financial Services, Digital Transformation, Virtual Reality, Digital Assets, Wealth Management, Fintech, Smart Contracts, Decentralized Finance, Crypto Exchange, NFTs, Tokenization"
        />
        <link rel="canonical" href="https://bstgroup.world/contact-us" />
      </Helmet>
      <Header />

      <div className="main-page-wrapper">
        <div
          className="inner-banner-one pt-225 lg-pt-200 md-pt-150 pb-100 md-pb-70 position-relative"
          style={{ backgroundImage: "url(../assets/image/about/contact.png)" }}
        >
          <div className="container position-relative">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <h1 className="hero-heading d-inline-block text-white position-relative">
                  Connect with BST Group now!{" "}
                </h1>
              </div>
              <div className="col-xl-4 col-lg-5 ms-auto">
                <p className="text-white text-lg mb-70 lg-mb-40">
                  For any inquiries or questions, reach out to our team today.
                </p>
                {/* <ul className="style-none d-inline-flex pager">
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>/</li>
                                    <li>Contact</li>
                                </ul> */}
              </div>
            </div>
          </div>
        </div>

        <div className="contact-us-section pt-150 lg-pt-80">
          <div className="container">
            <div className="position-relative">
              <div className="row">
                <div className="col-12 m-auto">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="address-block-one text-center mb-40 wow fadeInUp">
                        <div className="icon rounded-circle d-flex align-items-center justify-content-center m-auto">
                          <img
                            src="assets/image/icon/icon_90.svg"
                            data-src="assets/image/icon/icon_90.svg"
                            alt="Address"
                            className="lazy-img"
                          />
                        </div>
                        <h5 className="title">Our Address</h5>
                        <p>
                          803, Reef Tower Cluster O, JLT, <br /> Dubai, United
                          Arab Emirates
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="address-block-one text-center mb-40 wow fadeInUp">
                        <div className="icon rounded-circle d-flex align-items-center justify-content-center m-auto">
                          <img
                            src="assets/image/icon/icon_91.svg"
                            data-src="assets/image/icon/icon_91.svg"
                            alt="Contact"
                            className="lazy-img"
                          />
                        </div>
                        <h5 className="title">Contact</h5>
                        <p>
                          Open to chat or give us call at <br />
                          <a
                            href="tel:310.841.5500"
                            className="call text-lg fw-500"
                          >
                            +971557584619
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="address-block-one text-center mb-40 wow fadeInUp">
                        <div className="icon rounded-circle d-flex align-items-center justify-content-center m-auto">
                          <img
                            src="assets/image/icon/icon_92.svg"
                            data-src="assets/image/icon/icon_92.svg"
                            alt="Email"
                            className="lazy-img"
                          />
                        </div>
                        <h5 className="title">Email</h5>
                        <p>
                          Get in touch. <br />
                          <a
                            href="mailto:info@bstgroup.world"
                            className="webaddress"
                          >
                            info@bstgroup.world
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-wrapper light-bg mt-80 lg-mt-40 mb-80">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="d-flex flex-column flex-lg-column-reverse">
                      <div className="row">
                        <div className="col-md-8 col-6 me-auto ms-auto">
                          <img
                            src="assets/image/icon/contact.svg"
                            data-src="assets/image/icon/contact.svg"
                            alt="contact"
                            className="lazy-img me-auto ms-auto"
                          />
                        </div>
                      </div>
                      <div className="title-one text-center text-lg-start md-mt-20 mb-70 md-mb-30">
                        <h2>Have inquiries? Reach out via message</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-style-one ps-xl-5">
                      <div className="messages" />
                      <div className="row controls">
                        <div className="col-12">
                          <div className="input-group-meta form-group mb-30">
                            <label htmlFor="">Name*</label>
                            <input
                              type="text"
                              placeholder="Your Name*"
                              name="name"
                              data-error="Name is required."
                              value={formData.name}
                              onChange={handleChange}
                            />
                            {validation.name && <div>Name Required</div>}
                            <div className="help-block with-errors" />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="input-group-meta form-group mb-40">
                            <label htmlFor="">Email*</label>
                            <input
                              type="email"
                              placeholder="Email Address*"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                            />
                            {validation.email && (
                              <div>
                                {formData.email
                                  ? "Invalid email"
                                  : "Email Required"}
                              </div>
                            )}
                            <div className="help-block with-errors" />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="input-group-meta form-group mb-35">
                            <textarea
                              placeholder="Your message*"
                              name="message"
                              required="required"
                              data-error="Please,leave us a message."
                              defaultValue={""}
                              value={formData.message}
                              onChange={handleChange}
                            />
                            {validation.message && <div>Message Required</div>}
                            <div className="help-block with-errors" />
                          </div>
                        </div>
                        <div className="col-12">
                          <button
                            className="btn-four tran3s w-100 d-block"
                            onClick={() => handleSubmit()}
                          >
                            Send Message
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="map-banner mt-120 lg-mt-80">
                            <div className="gmap_canvas h-100 w-100">
                                <iframe
                                    className="gmap_iframe h-100 w-100"
                                    src="https://maps.google.com/maps?width=600&height=400&hl=en&q=dhaka%20collage&t=&z=12&ie=UTF8&iwloc=B&output=embed"
                                />
                            </div>
                        </div> */}
        </div>

        <Footer />
      </div>
    </>
  );
}