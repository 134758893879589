import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Team from '../components/Team'
import { Helmet } from 'react-helmet'

export default function BstTeam() {
    return (
        <>
            <Helmet>
                <title>Meet Our Team |BST Group Team | Dr. Farooq Abdal, Mr. Abdul Hussain Jaffar, Mr. Ali Taqi Al Lawat</title>
                <meta name="title" content="Meet Our Team |BST Group Team | Dr. Farooq Abdal, Mr. Abdul Hussain Jaffar, Mr. Ali Taqi Al Lawat" />
                <meta name="description" content="Explore the profiles of Dr. Farooq Abdal, Founder & Managing Director, Mr. Abdul Hussain Jaffar, Chairman, and Mr. Ali Taqi Al Lawati, Business Development Manager at BST Group. Learn about their roles, backgrounds, and contributions to our company." />
                <meta name="keywords" content="Web3 company team, BST Group executives, Web3 leadership, Dr. Farooq Abdal profile, Abdul Hussain Jaffar biography, Ali Taqi Al Lawati background, BST Group founders, Blockchain team BST, Crypto leadership BST, NFT experts BST, Web3 innovators, BST Group blockchain specialists, Web3 company leadership, BST Group management team, BST Group executive profiles" />
                <link rel="canonical" href="" />
            </Helmet>

            <Header />

            <div className='main-page-wrapper'>

                <div className="inner-banner-one light-bg pt-200 md-pt-150 pb-85 lg-pb-50 position-relative"
                    style={{ backgroundImage: "url(../assets/image/about/contact.png)" }}
                >
                    <div className="container position-relative">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <h1 className="hero-heading text-white d-inline-block position-relative me-xl-4">
                                    Team{" "}
                                </h1>
                            </div>
                            <div className="col-xl-4 col-lg-5 ms-auto">
                                <p className="text-white text-lg mb-0">
                                    Founders & Partners
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="team-details light-bg border-top pt-120 lg-pt-80 pb-150 lg-pb-80">
                    <div className="container">
                        <div className="bg-wrapper">
                            <div className="row m-0 justify-content-sm-start justify-content-center">
                                <div className="col-lg-3 col-md-6 col-sm-7 pt-45 padding_left pe-xl-4 pe-lg-3 pe-md-4 pe-3">
                                    <div className='team_card'>
                                        <img src='assets/image/team/team3.png' />
                                        <div className='pt-3 text-center pb-2'>
                                            <h2 className="name fw-bold font-22">Dr. Farooq Abdal</h2>
                                            <div className="post">Founder & Managing Director</div>
                                        </div>
                                    </div>
                                    {/* <div className="member-img" style={{ backgroundImage: "url(../assets/image/team/team3.png)" }} /> */}
                                </div>
                                <div className="col-lg-9 col-md-12 pt-45 pb-45 ps-xl-4 ps-lg-3 ps-md-4 pe-md-4 ps-3 pe-xl-4 pe-md-3 pe-3 h-100">
                                    <div className="">
                                        <h3 className=''>Details</h3>
                                        <table className="w-75 mt-25 lg-mt-20 details_table">
                                            <tbody>
                                                <tr>
                                                    <td>Position:</td>
                                                    <td>Founder / Managing Director</td>
                                                </tr>
                                                <tr>
                                                    <td>Nationality:</td>
                                                    <td>India</td>
                                                </tr>
                                                <tr>
                                                    <td>Company Name:</td>
                                                    <td>BST Group</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <h6 className="fw-bold">Biography</h6>
                                        <p className='mb-0'>
                                            Dr. Farooq Abdal stands as a distinguished entrepreneur and visionary, holding the positions of Founder and Managing Director within the esteemed BST Group of Companies. With an extensive experience surpassing fifteen years, he has solidified his stature as a leading figure in the global blockchain and gemstone industry. Driven by an unwavering commitment to democratize financial opportunities, he has successfully pioneered the tokenization of the rare and precious Kashmir blue sapphire inventory, positioning it as a coveted commodity on stock exchanges worldwide.
                                        </p>
                                        <p className='mb-0'>
                                            Innovating at the crossroads of technology and well-being, Dr. Abdal introduced the revolutionary Bio Sapphire AMized Fusion Technology (BSAFT) and meticulously crafted the Bio-Sapphire Bracelet, providing comprehensive protection against radiations and electromagnetic frequencies. Committed to regulatory compliance, he leads the launch of BSTC, marking a significant milestone as the world’s first fully decentralized security token backed by the largest collection of Kashmir blue sapphires. Supported by the Native Token of the cutting-edge RWA Tokenization Blockchain, BST CHAIN, this venture signifies a groundbreaking leap in the realm of digital asset.
                                        </p>
                                        <p className='mb-0'>
                                            Dr. Abdal’s mission transcends business, as he envisions securing the future distribution of Kashmir blue sapphires and contributing to smart city projects on a global scale. Prioritizing the generation of substantial employment and championing the Sapphire Wealth Project, he strives to ensure the widespread distribution of this natural treasure. His dedication to innovation is further exemplified through the E-Sapphire Mining platform, facilitating intelligent digital mining of sapphires.
                                        </p>
                                        <p className='mb-0'>
                                            At the helm of the BST Group, Dr. Abdal has strategically positioned it as a trailblazing wealth management platform. Integrating ring-fencing strategies for institutional investors and Ibanized personal accounts for individuals, he underscores his commitment to innovation through flagship projects like Blue Sapphire of Kashmir and E-Sapphire (BST). The group also proudly stands as the founder of the Credible Digital Asset and Social Map Monetizing system.
                                        </p>
                                        <p className='mb-0'>
                                            A luminary in the realm of cryptocurrency, Dr. Abdal introduced BST as one of the pioneering cryptocurrencies in 2019, headquartered in Dubai. Under his leadership, BST aims to reshape the global economic landscape into one that is vibrant, secure, and prosperous. His visionary approach aligns with fostering wealth circulation and effectively addressing the limitations of conventional financial systems.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-wrapper mt-50">
                            <div className="row m-0">
                                <div className="col-lg-3 col-md-6 col-sm-7 pt-45 padding_left pe-xl-4 pe-lg-3 pe-md-4 pe-3">
                                    <div className='team_card'>
                                        <img src='assets/image/team/team1.png' />
                                        <div className='pt-3 text-center pb-2'>
                                            <h2 className="name fw-bold font-22">Mr. Abdul Hussain Jaffar</h2>
                                            <div className="post">Partner</div>
                                        </div>
                                    </div>
                                    {/* <div className="member-img" style={{ backgroundImage: "url(../assets/image/team/team3.png)" }} /> */}
                                </div>
                                <div className="col-lg-9 col-md-12">
                                    <div className="pt-45 pb-45 ps-xl-4 ps-lg-0 ps-3 ps-md-4 pe-md-4 pe-xl-4 pe-lg-3 pe-3 h-100">
                                        <h3 className=''>Details</h3>
                                        <table className="w-75 mt-25 lg-mt-20 details_table">
                                            <tbody>
                                                <tr>
                                                    <td>Position:</td>
                                                    <td>Partner</td>
                                                </tr>
                                                <tr>
                                                    <td>Nationality:</td>
                                                    <td>Omani</td>
                                                </tr>
                                                <tr>
                                                    <td>Company Name:</td>
                                                    <td>BST Group</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <h6 className="fw-bold">Biography</h6>
                                        <p className='mb-0'>
                                            Abdul Hussain Jaffar, an Omani national married with three children is a business man and investor with outstanding leadership skill who proved as successful businessman with his hard - work and dedication. He started his own business in 1981 after resigning the job from Ministry of finance and Economy in 1981 where he was holding the position of director of accounts and loan. He has gained a lot of rich experience in all banking sector during his tenure.
                                        </p>
                                        <p className='mb-0'>
                                            Dedicated to national saving through energy and ecological conservation, he started his business carrier on 1983 under the name of Mutrah Insofoam Co. S.A.O.G, the first company to start manufacturing of Expanded Polystyrene products in Oman. Two years later, the company acquired the latest European technology at that time and since then there was no looking back for the company.
                                            Steel Panel L.L.C, sister concern of Mutrah Insofoam group under his leadership was incorporated in 1995 for the manufacturing of insulated sandwich panels, profile sheets and building system for high quality conformity to international standards, using latest Australian machine & technology.
                                        </p>
                                        <p className='mb-0'>
                                            Al Khaleej Insulation Co. L.L.C , the next business establishment under his leadership was started in 2000 for the manufacturing of extruded polystyrene foam, till now is the only extruded polystyrene manufacturer in Oman. He also contributed his experience to the field of information technology of Oman by being partner of IT firms Scintillant Infotech L.L.C & Development office.
                                        </p>
                                        <p className='mb-0'>
                                            In the field of investment industry, he used his business experience to make benefit from Oman and outside Oman. He is member of Oman British Friendship association, Oman American business Centre and Oman- China friendship association. Swimming is his hobby.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="team-details1" className="bg-wrapper mt-50">
                            <div className="row m-0">
                                <div className="col-lg-3 col-md-6 col-sm-7 pt-45 padding_left pe-xl-4 pe-lg-3 pe-md-4 pe-3">
                                    <div className='team_card'>
                                        <img src='assets/image/team/team2.png' />
                                        <div className='pt-3 mb-4 text-center pb-2'>
                                            <h2 className="name fw-bold font-22">Mr. Ali Taqi Al Lawati</h2>
                                            <div className="post">Business Development Manager</div>
                                        </div>
                                    </div>
                                    {/* <div className="member-img" style={{ backgroundImage: "url(../assets/image/team/team3.png)" }} /> */}
                                </div>
                                <div className="col-lg-9 col-md-12">
                                    <div className="pt-45 pb-45 ps-xl-4 ps-md-4 pe-md-4 ps-lg-0 ps-3 pe-xl-4 pe-lg-3 pe-3 h-100">
                                        <h3 className=''>Details</h3>
                                        <table className="w-75 mt-25 lg-mt-20 details_table">
                                            <tbody>
                                                <tr>
                                                    <td>Position:</td>
                                                    <td>Business Development Manager</td>
                                                </tr>
                                                <tr>
                                                    <td>Nationality:</td>
                                                    <td>Omani</td>
                                                </tr>
                                                <tr>
                                                    <td>Company Name:</td>
                                                    <td>BST Group</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <h6 className="fw-bold">Biography</h6>
                                        <p>
                                            Ali Taqi is an MBA graduate from Bedfordshire University (UK), and holds a BSc Finance degree from Majan College (Oman). Prior to joining Muscat Finance, Mr Ali Taqi had 10 years of banking experience within the Compliance department. And well-known person in crypto industry in oman.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Team />

                <Footer />
            </div>
        </>
    )
}