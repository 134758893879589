import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Team from '../components/Team'
import { Helmet } from 'react-helmet'

export default function BstcGame() {
    return (
        <>
            <Helmet>
                <title>Elevating Entertainment in the Web3 Era</title>
                <meta name="title" content="Elevating Entertainment in the Web3 Era" />
                <meta name="description" content="Experience the next level of gaming entertainment with BST Games, offering immersive experiences and innovative gameplay in the web3 era." />
                <meta name="keywords" content="Games, Gaming, Web3, Blockchain, Innovation, Entertainment, Virtual Games, Crypto Games, NFT Games, Gaming Revolution, Web3 Games, Game Development, Gaming Technology, BST Games, Gaming Industry, Crypto Trends, Web3 Gaming, Blockchain Games, Virtual Reality Games, Game Innovation, Gaming Trends, BST Trends, Digital Entertainment" />
                <link rel="canonical" href="https://bstgroup.world/bst-games" />
            </Helmet>
            <Header />

            <div className='main-page-wrapper'>

                <div
                    className="inner-banner-one pt-225 lg-pt-200 md-pt-150 pb-100 md-pb-70 position-relative"
                    style={{ backgroundImage: "url(../assets/image/srvices/game.jpg)" }}
                >
                    <div className="container position-relative">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <h1 className="hero-heading d-inline-block position-relative me-xl-4 text-white">
                                    BST Games
                                </h1>
                            </div>
                            <div className="col-xl-4 col-lg-5 ms-auto">
                                <p className="text-white text-lg mb-70 lg-mb-40">
                                    Explore vibrant communities, captivating storylines, and dynamic mechanics for endless engagement.
                                </p>
                                <ul className="style-none d-inline-flex pager">
                                    <li>
                                        <a href="#">Explore Now</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-details mt-150 lg-mt-80 mb-100 lg-mb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 order-lg-last">
                                <div className="details-meta ps-xxl-5 ps-xl-3">
                                    <h2>Elevate gaming with BST Games' immersive experiences across diverse genres.</h2>
                                    <p>
                                        BST Games leads the charge in gaming innovation, delivering a diverse portfolio of immersive experiences across various genres. From action-packed adventures to mind-bending puzzles, our high-quality graphics and captivating gameplay transport players to vibrant worlds filled with endless possibilities.{" "}
                                    </p>
                                    <p>
                                        Step into the world of BST Games, where immersive storylines and dynamic mechanics await. Join our vibrant community of gamers, connect with fellow enthusiasts, and embark on epic adventures together. With BST Games, entertainment reaches new heights.
                                    </p>
                                    <h3 className='mt-4'>Gaming Evolution</h3>
                                    <p>
                                        BST Games revolutionizes entertainment with immersive experiences and vibrant gaming communities that captivate players worldwide.
                                    </p>
                                    <div className="line-wrapper pb-60 mt-60 lg-mt-40">
                                        <div className="row">
                                            <div className="col-md-4 wow fadeInUp">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_81.svg"
                                                            data-src="assets/image/icon_81.svg"
                                                            alt="Diverse Games"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">
                                                        Diverse Games
                                                    </h4>
                                                    <p className="m0">
                                                        Explore a wide range of genres, including action, adventure, puzzles, and simulation, catering to all players.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 wow fadeInUp" data-wow-delay="0.1s">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_82.svg"
                                                            data-src="assets/image/icon/icon_82.svg"
                                                            alt="Stunning Graphics"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">
                                                        Stunning Graphics
                                                    </h4>
                                                    <p className="m0">
                                                        Immerse yourself in rich and vibrant gaming worlds with BST Games' commitment to high-quality graphics.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 wow fadeInUp" data-wow-delay="0.2s">
                                                <div className="card-style-sixteen text-center mt-40">
                                                    <div className="icon m-auto tran3s rounded-circle d-flex align-items-center justify-content-center">
                                                        <img
                                                            src="assets/image/icon/icon_83.svg"
                                                            data-src="assets/image/icon/icon_83.svg"
                                                            alt="Vibrant Community"
                                                            className="lazy-img"
                                                        />
                                                    </div>
                                                    <h4 className="fw-bold mt-35 lg-mt-30 mb-15">Vibrant Community</h4>
                                                    <p className="m0">
                                                        Connect, collaborate, and share your passion for gaming with fellow enthusiasts in BST Games' inclusive community.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Team />

                <Footer />
            </div >
        </>
    )
}