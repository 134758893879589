import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Team from '../components/Team'
import { Helmet } from 'react-helmet'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ProgressiveImage from 'react-progressive-graceful-image'

export default function Gallery() {

  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     const page = document.getElementById('page')
  //     const loader = document.getElementById('loader')
  //     loader.classList.add('d-none')
  //     page.classList.remove('d-none')
  //   }, 2000)
  // }, []);

  const images = [
    { src: "assets/image/gallery/bst1.jpg" },
    { src: "assets/image/gallery/bst2.jpg" },
    { src: "assets/image/gallery/bst3.jpg" },
    { src: "assets/image/gallery/bst4.jpg" },
    { src: "assets/image/gallery/bst5.jpg" },
    { src: "assets/image/gallery/bst6.jpg" },
    { src: "assets/image/gallery/bst7.JPG" },
    { src: "assets/image/gallery/bst8.jpg" },
    { src: "assets/image/gallery/bst9.JPG" },
    { src: "assets/image/gallery/bst10.JPG" },
    { src: "assets/image/gallery/bst11.JPG" },
    { src: "assets/image/gallery/bst12.JPG" },
    { src: "assets/image/gallery/bst13.JPG" },
    { src: "assets/image/gallery/bst15.jpg" },
    { src: "assets/image/gallery/bst16.JPG" },
    { src: "assets/image/gallery/bst17.JPG" },
    { src: "assets/image/gallery/bst19.JPG" },
    { src: "assets/image/gallery/bst20.jpg" },
    { src: "assets/image/gallery/bst21.JPG" },
    { src: "assets/image/gallery/bst22.JPG" },
    { src: "assets/image/gallery/bst23.jpg" },
    { src: "assets/image/gallery/bst24.JPG" },
    { src: "assets/image/gallery/bst25.JPG" },
    { src: "assets/image/gallery/bst26.JPG" },
    { src: "assets/image/gallery/bst27.JPG" },
    { src: "assets/image/gallery/bst28.JPG" },
    { src: "assets/image/gallery/bst29.jpg" },
    { src: "assets/image/gallery/bst30.jpg" },
    { src: "assets/image/gallery/bst31.jpg" },
    { src: "assets/image/gallery/bst32.jpg" },
    { src: "assets/image/gallery/bst33.jpg" },
    { src: "assets/image/gallery/bst34.jpg" },
    { src: "assets/image/gallery/bst35.jpg" },
    { src: "assets/image/gallery/bst14.JPG" },
    { src: "assets/image/gallery/bst18.JPG" }
  ];


  return (
    <>
      {/* <div className='page_loader' id='loader'>
        <div class="loader"></div>
      </div> */}
      {/* <div id='page' className='d-none'> */}
      <Helmet>
        <title>BST Group Gallery | BST Group's Innovations, Achievements, Web3 Sectors</title>
        <meta name="title" content="BST Group Gallery | BST Group's Innovations, Achievements, Web3 Sectors" />
        <meta name="description" content="Explore BST Group's gallery showcasing innovations and achievements in Web3 sectors. Discover visuals highlighting BSTC Bank, SappheX, BSTravel, Blue Fashion, BST Metaverse, and more, reflecting our leadership in digital innovation." />
        <meta name="keywords" content="Web3 company gallery, BST Group achievements, Web3 innovations, BSTC Bank visuals, SappheX images, BSTravel gallery, Blue Fashion photos, BST Metaverse achievements, BST Group team, BST Group leaders, Web3 experts, blockchain innovations, crypto achievements, NFT innovations" />
        <link rel="canonical" href="https://bstgroup.world/gallery" />
      </Helmet>
      <Header />
      <div className='main-page-wrapper'>

        <div
          className="inner-banner-one pt-225 lg-pt-200 md-pt-150 pb-100 md-pb-70 position-relative"
          style={{ backgroundImage: "url(../assets/image/about/contact.png)" }}
        >
          <div className="container position-relative">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <h1 className="hero-heading text-white d-inline-block position-relative me-xl-4">
                  Gallery{" "}
                </h1>
              </div>
              <div className="col-xl-4 col-lg-5 ms-auto">
                {/* <p className="text-white text-lg mb-70 lg-mb-40">
                                    Don’t find the answer? We can help.
                                </p> */}
                {/* <ul className="style-none d-inline-flex pager">
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>/</li>
                                    <li>BSTravel</li>
                                </ul> */}
              </div>
            </div>
          </div>
        </div>
        <div className="team-section-one mt-150 lg-mt-80 mb-100 lg-mb-40">
          <div className="container">
            <div className="position-relative">
              <div className="row">
                {
                  images.map((i, index) =>
                    <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp" key={index}>
                      <div className="card-stnter jusyle-four mb-25 lg-mb-20">
                        <div className="d-flex align-items-cetify-content-center position-relative overflow-hidden">
                          {/* <img src={i.src} alt="gallery" className="lazy-img w-100" /> */}
                          {/* <LazyLoadImage
                              src={i.src}
                              className='lazy-img w-100 loading'
                              alt={`gallary${index}`}
                              threshold={5000}
                              effect='opacity'
                              loading='eager'
                            /> */}
                          <ProgressiveImage src={i.src}>
                            {(src, loading) => (
                              <img
                                className={`lazy-img w-100${loading ? " loading" : " loaded"}`}
                                src={src}
                                alt={`gallary${index}`}
                              />
                            )}
                          </ProgressiveImage>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>

        <Team />

        <Footer />
      </div >
      {/* </div> */}
    </>
  )
}