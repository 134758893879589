import React from 'react'

export default function Header() {

    return (
        <>
            <header className="theme-main-menu menu-overlay menu-style-four white-vr sticky-menu">
                <div className="inner-content position-relative">
                    <div className="top-header">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="logo order-lg-0">
                                <a href="/" className="d-flex align-items-center">
                                    <img src="/assets/image/logo/logo.svg" alt="logo" />
                                </a>
                            </div>
                            <div className='d-flex align-items-center gap-lg-5 gap-4'>
                                <div className="right-widget d-none d-md-block ms-auto ms-lg-0 me-lg-0 order-lg-3">
                                    <a href="/contact-us" className="btn-twelve tran3s">
                                        CONTACT US
                                    </a>
                                </div>
                                <nav className="navbar navbar-expand-lg p0 order-lg-2">
                                    <button
                                        className="navbar-toggler d-block d-lg-none"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#navbarNav"
                                        aria-controls="navbarNav"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                    >
                                        <span />
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarNav">
                                        <ul className="navbar-nav align-items-lg-center">
                                            <li className="d-block d-lg-none">
                                                <div className="logo">
                                                    <a href="/" className="d-block">
                                                        <img src="/assets/image/logo/logo.svg" alt="logo" />
                                                    </a>
                                                </div>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link" href="/">
                                                    Home
                                                </a>
                                            </li>
                                            <li className="nav-item dropdown mega-dropdown-sm">
                                                <a className="nav-link" href="/about-us">
                                                    About Us
                                                </a>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                                    data-bs-auto-close="outside" aria-expanded="false">
                                                    Portfolio
                                                </a>
                                                <ul className='dropdown-menu'>
                                                    <li>
                                                        <a href="https://bstchain.io/" target='_blank' className="dropdown-item">
                                                            <span>BST Chain</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://bstscan.com/" target='_blank' className="dropdown-item">
                                                            <span>BST Explorer</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://bsaft.com/product/bracelet/?bst=990131" target='_blank' className="dropdown-item">
                                                            <span>BioSapphire Bracelet</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://sapphirechain.group/" target='_blank' className="dropdown-item">
                                                            <span>Sapphire Trading</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://espmining.io/dashboard" target='_blank' className="dropdown-item">
                                                            <span>E-Sapphire</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://shorturl.at/hmHK8" target='_blank' className="dropdown-item">
                                                            <span>BST Cricket Gamefi</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://play.google.com/store/apps/details?id=com.bstchat" target='_blank' className="dropdown-item">
                                                            <span>BST Chat W3</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link" href="/news-list">
                                                    News
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="assets/image/whitepaper/WHITEPAPER_VERSION_2.0.pdf" target='_blank'>
                                                    Whitepaper
                                                </a>
                                            </li>
                                            <li className="d-md-none ps-2 pe-2">
                                                <a href="/contact-us" className="btn-twelve w-100 mt-15 tran3s">
                                                    CONTACT US
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}