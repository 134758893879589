import React from 'react'
import Header from '../components/Header'
import Payments from '../components/Payments'
import Footer from '../components/Footer'
import Team from '../components/Team'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export default function Home() {
  return (
    <>
      <Helmet>
        <title>BST Group: Unlocking potential, exceeding expectations</title>
        <meta name="title" content="BST Group: Unlocking potential, exceeding expectations" />
        <meta name="description" content="Discover innovative solutions and cutting-edge technologies at BST Group. Explore our products and services, designed to revolutionize various industries." />
        <meta name="keywords" content="Innovation, Technology, Solutions, Cutting-edge, Revolutionize, Industries, Explore, Products, Services, Visionary, Future, Transformation, Digital, Blockchain, Metaverse, Cryptocurrency, Web3, SappheX, Financial, Empowerment, Virtual Reality, Immersive Experiences, Digital Landscapes, Collaboration, BST, BST Group, BST chain, Financial Services, Wealth Management, Fintech, Smart Contracts, Decentralized Finance, Crypto Exchange, NFTs, Tokenization, Digital Innovation, Emerging Technologie" />
        <link rel="canonical" href="https://bstgroup.world/" />
      </Helmet>

      <Header />

      <div className='main-page-wrapper'>

        {/* Hero section */}
        <div className="hero-banner-four position-relative">
          <div className='hero_banner'>
            <video autoPlay loop muted playsInline>
              <source src="assets/image/hero/diamond.mp4" />
            </video>

            <div className="container hero_content">
              <div className="row justify-content-center">
                <div className="col-xxl-9 col-md-8">
                  <h1 className="hero-heading text-white position-relative wow fadeInUp">BST Group <span>The Web3 Future with GemFi</span>{" "}</h1>
                  <div className="row justify-content-center">
                    <div className="col-xl-8 col-lg-9">
                      <p className="pera_content text-white pt-20 xl-pt-15 lg-pt-10 wow fadeInUp text-center fw-400 mb-0" data-wow-delay="0.1s">
                        Empowering Sustainable Economies Through Innovation
                        {/* <br className='d-sm-block d-none' /> */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-feature-six position-relative mt-60">
          <div className="container">
            <div className="position-relative pb-140 xl-pb-100 md-pb-60">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="title-two mb-30">
                    <div className="upper-title">Unify Excellence</div>
                    <h2>Global Reach, Sapphire Strength</h2>
                  </div>
                  <p className="text-lg mb-35 pe-xl-5 me-xl-4">
                    BST Group, a global network with dominant Kashmir Blue Sapphire holdings.
                  </p>
                  <a href="/contact-us" className="btn-thirteen tran3s me-5">
                    Apply Now
                  </a>
                </div>
                <div className="col-lg-4 ms-auto">
                  <div className="ps-xl-5 ms-xxl-2 md-mt-60">
                    <div className="card-style-ten border-bottom mb-60 lg-mb-40">
                      <h4 className="fw-bold">Global reach</h4>
                      <p>
                        BST Group, connecting the world through 21 global nodes.
                      </p>
                      <div className="main-count d-inline-block position-relative fw-bold"><span className="counter">21</span></div>
                      <div>Nodes Worldwide</div>
                    </div>
                    <div className="card-style-ten">
                      <h4 className="fw-bold">Sapphire Dominance</h4>
                      <p>
                        BST Group, proudly holding 70% of Blue Sapphire of Kashmir.
                      </p>
                      <div className="main-count d-inline-block position-relative fw-bold"><span className="counter">70</span>%</div>
                      <div>Sapphire Holders</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="media-wrapper">
                <img src="assets/image/about/hand.png" data-src="assets/image/about/hand.png" alt="hand" className="lazy-img w-100" />
                <img src="assets/image/about/diamond.png" data-src="assets/image/about/diamond.png" alt="diamond" className="lazy-img shapes screen_01" />
              </div>
              <img src="assets/image/about/shape_15.png" data-src="assets/image/about/shape_15.png" alt="icon" className="lazy-img shapes shape_01" />
              <img src="assets/image/icon/shape_16.svg" data-src="assets/image/icon/shape_16.svg" alt="icon" className="lazy-img shapes shape_02" />
            </div>
          </div>
        </div>

        <div className="block-feature-eight payment_section position-relative pt-90 lg-pt-80 pb-160 lg-pb-80 xs-pt-60">
          <div className="container">
            <div className="position-relative">
              <div className="title-two mb-20 lg-mb-10 ">
                <h2 className='text-white'>Embrace Web3 <br />
                  easily with BST
                </h2>
              </div>
              <p className="mb-0 text-16 text-white">
                Simplifying blockchain experiences across diverse sectors.
              </p>
              <div className='payment_slide pt-100 xs-pt-80'>
                <Payments />
              </div>
            </div>
          </div>
        </div>

        <div className="text-feature-one mt-120 xl-mt-100 lg-mt-80 xs-mt-60 pb-150 xl-pb-120 md-pb-80">
          <div className="container">
            <div className="line-wrapper position-relative">
              <div className="row align-items-center">
                <div className="col-lg-5 wow fadeInLeft">
                  <div className="title-one">
                    <div className="upper-title">About us</div>
                    <h2>Working for a Better Tomorrow with BST</h2>
                  </div>
                  <p className="text-lg mt-45 lg-mt-30 mb-35 lg-mb-30">
                    We innovate to empower industries globally, fostering growth and prosperity through cutting-edge solutions and an unwavering commitment to excellence.
                  </p>
                  {/* <a href="#" className="btn-three icon-link mt-15 md-mb-40">
                    <span>Request a Callback</span>
                    <img src="assets/image/icon/icon_09.svg" data-src="assets/image/icon/icon_09.svg" alt="" className="lazy-img icon ms-1" />
                  </a> */}
                </div>
                <div className="col-lg-6 ms-auto wow fadeInRight">
                  <div className="card-style-three d-flex pt-75 lg-pt-40 pb-45 lg-pb-20">
                    <img src="assets/image/icon/icon_10.svg" data-src="assets/image/icon/sicon_10.svg" alt="star" className="lazy-img icon" />
                    <div className="ps-4">
                      <h4 className="fw-bold mb-20">Mission</h4>
                      <p>
                        Our goal is to make blockchain and Web3 easy and handy for everyone, everywhere.
                      </p>
                    </div>
                  </div>
                  <div className="card-style-three d-flex pt-75 lg-pt-40 pb-45 lg-pb-20">
                    <img src="assets/image/icon/icon_11.svg" data-src="assets/image/icon/icon_11.svg" alt="vision" className="lazy-img icon" />
                    <div className="ps-4">
                      <h4 className="fw-bold mb-20">Our company vision</h4>
                      <p>
                        We imagine a future where blockchain and Web3 are everyday tools, helping people and businesses thrive.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <img src="assets/image/icon/shape_05.svg" data-src="assets/image/icon/shape_05.svg" alt="star" className="lazy-img shapes shape_01" />
            </div>
            {/* <div className="counter-wrapper mt-80 lg-mt-20">
              <div className="row">
                <div className="col-md-3 col-6">
                  <div className="counter-block-two text-center text-md-start mt-35 wow fadeInUp">
                    <div className="main-count fw-500"><span className="counter">120</span>+</div>
                    <p className="m0 text-md">Partner with us</p>
                  </div>                  
                </div>
                <div className="col-md-3 col-6">
                  <div className="counter-block-two text-center mt-35  wow fadeInUp" data-wow-delay="0.1s">
                    <div className="main-count fw-500">$<span className="counter">1.3</span>b+</div>
                    <p className="m0 text-md">Cumulative trading volume</p>
                  </div>
                  
                </div>
                <div className="col-md-3 col-6">
                  <div className="counter-block-two text-center mt-35  wow fadeInUp" data-wow-delay="0.2s">
                    <div className="main-count fw-500"><span className="counter">705</span>k</div>
                    <p className="m0 text-md">Successful Projects</p>
                  </div>
                 
                </div>
                <div className="col-md-3 col-6">
                  <div className="counter-block-two text-center text-md-end mt-35  wow fadeInUp" data-wow-delay="0.3s">
                    <div className="main-count fw-500">$<span className="counter">1.2</span>%</div>
                    <p className="m0 text-md">Low interest rate</p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="block-feature-eight digital_section position-relative pt-100 lg-pt-80">
          <div className="container">
            <div className="row justify-content-center">
              <div className='col-lg-9 col-md-10'>
                <div className="title-two mb-20 lg-mb-10 text-center">
                  <h2 className='text-white'>Most Credible Digital Asset In The World</h2>
                </div>
              </div>
              <div className='col-lg-6 col-md-11 text-center'>
                <p className="text-16 text-white mb-20">
                  BST's Blue Sapphire, the world's most credible digital asset, shines brightly in the BST Blockchain universe. It embodies trust and innovation, setting a new standard in the decentralized digital world.
                </p>
                {/* <div className="right-widget">
                  <a href="#" className="btn-twelve tran3s">
                    READ MORE
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="block-feature-one service_section position-relative light-bg-deep pt-90 lg-pt-80 xs-pt-60 pb-130 lg-pb-80">
          <div className="container">
            <div className="position-relative">
              <div className="title-two mb-100 md-mb-70 xs-mb-60">
                <h2 className='text-white'>The largest and <br className='d-sm-block d-none' /> most thriving ecosystem</h2>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-6 d-flex wow fadeInUp">
                  <div className="card-style-two service_card vstack tran3s w-100">
                    <h4 className="service_title">5000+</h4>
                    <p className="service_pera">
                      Transaction Per Seconds
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 d-flex wow fadeInUp card_space xs-mt-20" data-wow-delay="0.1s">
                  <div className="card-style-two service_card vstack tran3s w-100">
                    {/* <img src="images/lazy.svg" data-src="images/icon/icon_07.svg" alt="" className="lazy-img icon me-auto" /> */}
                    <h4 className="service_title">2000+</h4>
                    <p className="service_pera">
                      Contract Creators
                    </p>
                    {/* <a href="" className="arrow-btn tran3s mt-auto stretched-link">
                      <img src="images/lazy.svg" data-src="images/icon/icon_09.svg" alt="" className="lazy-img" />
                    </a> */}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 d-flex wow fadeInUp sm-mt-20" data-wow-delay="0.2s">
                  <div className="card-style-two service_card vstack tran3s w-100">
                    {/* <img src="images/lazy.svg" data-src="images/icon/icon_08.svg" alt="" className="lazy-img icon me-auto" /> */}
                    <h4 className="service_title">10B</h4>
                    <p className="service_pera">
                      Total Transactions
                    </p>
                    {/* <a href="" className="arrow-btn tran3s mt-auto stretched-link">
                      <img src="images/lazy.svg" data-src="images/icon/icon_09.svg" alt="" className="lazy-img" />
                    </a> */}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 d-flex wow fadeInUp card_space_top sm-mt-20">
                  <div className="card-style-two service_card active vstack tran3s w-100">
                    {/* <img src="images/lazy.svg" data-src="images/icon/icon_59.svg" alt="" className="lazy-img icon me-auto" /> */}
                    <h4 className="service_title">Dpos+</h4>
                    <p className="service_pera">
                      Consensus
                    </p>
                    {/* <a href="" className="arrow-btn tran3s mt-auto stretched-link">
                      <img src="images/lazy.svg" data-src="images/icon/icon_09.svg" alt="" className="lazy-img" />
                    </a> */}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 d-flex wow fadeInUp mt-25 sm-mt-20" data-wow-delay="0.1s">
                  <div className="card-style-two service_card vstack tran3s w-100">
                    {/* <img src="images/lazy.svg" data-src="images/icon/icon_60.svg" alt="" className="lazy-img icon me-auto" /> */}
                    <h4 className="service_title">500m</h4>
                    <p className="service_pera">
                      Total Supply
                    </p>
                    {/* <a href="" className="arrow-btn tran3s mt-auto stretched-link">
                      <img src="images/lazy.svg" data-src="images/icon/icon_09.svg" alt="" className="lazy-img" />
                    </a> */}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 d-flex wow fadeInUp card_space_top sm-mt-20" data-wow-delay="0.2s">
                  <div className="card-style-two service_card vstack tran3s w-100">
                    {/* <img src="images/lazy.svg" data-src="images/icon/icon_61.svg" alt="" className="lazy-img icon me-auto" /> */}
                    <h4 className="service_title">BST20</h4>
                    <p className="service_pera">
                      Token Type
                    </p>
                    {/* <a href="" className="arrow-btn tran3s mt-auto stretched-link">
                      <img src="images/lazy.svg" data-src="images/icon/icon_09.svg" alt="" className="lazy-img" />
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src="assets/image/icon/shape_05.svg" data-src="assets/image/icon/shape_05.svg" alt="star" className="lazy-img shapes shape_01" />
          <img src="assets/image/icon/shape_05.svg" data-src="assets/image/icon/shape_05.svg" alt="star" className="lazy-img shapes shape_02" />
        </div>

        <div id='ecosystem' className="block-feature-one position-relative pt-90 lg-pt-80 pb-70 lg-pb-50 sm-pb-60">
          <div className="container">
            <div className="position-relative">
              <div className="title-two mb-50">
                <h2 className='text_dark blog_title'>The most extensive <br /> and vibrant ecosystem.  </h2>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <Link to={"/bst-bank"}>
                    <div className="blog_card w-100">
                      <video autoPlay muted loop>
                        <source src="assets/image/video/bsct-bank.mp4" type="video/mp4" />
                      </video>
                    </div>
                    <div className='blog_card_title'>
                      <h3>BST Bank</h3>
                      <p>Empowering you to take control of your financial future.</p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-6 mt-0 md-mt-40">
                  <Link to={'/sapphex'}>
                    <div className="blog_card w-100">
                      <img src='assets/image/about/exchange.png' alt='exchange' />
                    </div>
                    <div className='blog_card_title'>
                      <h3>SappheX</h3>
                      <p>Making digital asset trading easy and secure for everyone with Web3.</p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-6 mt-50 xl-mt-40 md-mt-40">
                  <Link to={'/bstravel'}>
                    <div className="blog_card w-100">
                      <img src='assets/image/about/travel.png' alt='travel' />
                    </div>
                    <div className='blog_card_title'>
                      <h3>BSTravel</h3>
                      <p>Make your journeys effortless with our comprehensive travel solutions over blockchain.</p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-6 mt-50 xl-mt-40 md-mt-40">
                  <Link to={'/blue-fashion'}>
                    <div className="blog_card w-100">
                      <img src='assets/image/about/fashion.gif' alt='fashion' />
                    </div>
                    <div className='blog_card_title'>
                      <h3>Blue Fashion</h3>
                      <p>A unique blend of style and innovation in fashion on the Meta Land.</p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-6 mt-50 xl-mt-40 md-mt-40">
                  <Link to={'/bst-metaverse'}>
                    <div className="blog_card v_metaverse w-100">
                      <video autoPlay muted loop>
                        <source src="assets/image/video/metaverse.mp4" type="video/mp4" />
                      </video>
                    </div>
                    <div className='blog_card_title'>
                      <h3>BST Metaverse</h3>
                      <p>Transforming the way you interact with virtual reality worlds with ease.</p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-6 mt-50 xl-mt-40 md-mt-40">
                  <Link to={'/bst-games'}>
                    <div className="blog_card w-100">
                      <img src='assets/image/about/game.png' alt='game' />
                    </div>
                    <div className='blog_card_title'>
                      <h3>BST Games</h3>
                      <p>Creating fun and exciting games for all ages with new generational technology.</p>
                    </div>
                  </Link>
                </div>
                <div className="col-12 mt-50 xl-mt-40 md-mt-40">
                  <Link to={'/bst-management'}>
                    <div className="blog_card h-auto w-100">
                      <video autoPlay muted loop>
                        <source src="assets/image/video/wealth.mp4" type="video/mp4" />
                        <source src="assets/image/video/wealth.mp4" type="video/ogg" />
                      </video>
                    </div>
                    <div className='blog_card_title'>
                      <h3>Asset and Wealth Management</h3>
                      <p>Steering you towards financial success with expert guidance and the security of Web3.</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Team />

        <Footer />
      </div>
    </>
  )
}