import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'

export default function Roadmap() {
    return (
        <>
            <Helmet>
                <title>BST Group Roadmap |Transformative Ventures from 1990 to 2030</title>
                <meta name="title" content="BST Group Roadmap |Transformative Ventures from 1990 to 2030" />
                <meta name="description" content="Explore the comprehensive roadmap of BST Group's evolution from Blue Sapphire Trading to pioneering blockchain ventures and metaverse expansion. Discover key milestones and initiatives shaping the future of commerce and technology." />
                <meta name="keywords" content="BST Group, Progressive Roadmap, Blue Sapphire Trading, Blockchain Ventures, Metaverse Expansion, Digital Economy, Commercial Enterprise, Tokenization, Crypto Reserve, BioSapphire Bracelets, E-Sapphire, BST Chain, SappheX, Dex, BSTravel, Blue Fashion Magazine, V-Commerce Platform, BST Nano-Ventures, BST Academy, Neo-Banking, DLT MeshNET, B2B Trade Finance, Crypto Wallets, Exchange Listing, Economic Vision" />
                <link rel="canonical" href="https://bstgroup.world/roadmap" />
            </Helmet>

            <Header />

            <div className='main-page-wrapper'>

                <div
                    className="inner-banner-one pt-225 lg-pt-200 md-pt-150 pb-100 md-pb-70 position-relative"
                    style={{ backgroundImage: "url(../assets/image/about/contact.png)" }}
                >
                    <div className="container position-relative">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <h1 className="hero-heading d-inline-block text-white position-relative">
                                    BST Group Progressive Roadmap
                                </h1>
                            </div>
                            <div className="col-xl-4 col-lg-5 ms-auto">
                                <p className="text-white text-lg mb-70 lg-mb-40">
                                    The roadmap signifies the metamorphic progression of Kashmir into E-Sapphires, a journey spanning decades of planning and execution.
                                </p>
                                {/* <ul className="style-none d-inline-flex pager">
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>/</li>
                                    <li>Contact</li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="block-feature-one position-relative light-bg-deep pt-120 lg-pt-60 pb-130 lg-pb-60">
                    <div className="container">
                        <div className="position-relative">
                            {/* <div className="title-one text-center mb-40 lg-mb-10">
                                <h2>Our Services</h2>
                                <p className="text-lg mt-20">Inciddnt ut labore et dolor magna aliu. ad mim venam, quis nostru </p>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-4 col-md-6 d-flex wow fadeInUp">
                                    <div className="card-style-two vstack tran3s w-100 mt-30">
                                        <img src="assets/image/icon/roadmap.svg" data-src="assets/image/icon/roadmap.svg" alt="roadmap" className="lazy-img icon me-auto" />
                                        <h4 className="fw-bold mt-30 mb-25">1990-2019</h4>
                                        <ul>
                                            <li><p className="mb-10">Blue Sapphire Trading</p></li>
                                            <li><p className="mb-10">Economic Vision</p></li>
                                            <li><p className="mb-10">Concept Evolution</p></li>
                                            <li><p>Resource Allocations</p></li>
                                        </ul>
                                        {/* <a href="service-details.html" className="arrow-btn tran3s mt-auto stretched-link"><img src="images/lazy.svg" data-src="images/icon/icon_09.svg" alt="" className="lazy-img" /></a> */}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 d-flex wow fadeInUp" data-wow-delay="0.1s">
                                    <div className="card-style-two vstack tran3s w-100 mt-30">
                                        <img src="assets/image/icon/roadmap.svg" data-src="assets/image/icon/roadmap.svg" alt="roadmap" className="lazy-img icon me-auto" />
                                        <h4 className="fw-bold mt-30 mb-25">2020-2022</h4>
                                        <ul>
                                            <li><p className="mb-10">BST Gemstones Trading LLC-UAE</p></li>
                                            <li><p className="mb-10">BS Token Launch-Exchange Listing</p></li>
                                            <li><p className="mb-10">Commercial Enterprise Valuation</p></li>
                                            <li><p>BioSapphire Bracelets</p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 d-flex wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="card-style-two vstack tran3s w-100 mt-30">
                                        <img src="assets/image/icon/roadmap.svg" data-src="assets/image/icon/roadmap.svg" alt="roadmap" className="lazy-img icon me-auto" />
                                        <h4 className="fw-bold mt-30 mb-25">2023-2024</h4>
                                        <ul>
                                            <li><p className="mb-10">Asset Acquisition / Tokenization</p></li>
                                            <li><p className="mb-10">BST CHain/SappheX/Dex</p></li>
                                            <li><p className="mb-10">BSTravel</p></li>
                                            <li><p className="mb-10">Blue Fashion Magazine</p></li>
                                            <li><p className="mb-10">E-Sapphire Global Launch</p></li>
                                            <li><p>First Metaverse Auction</p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 d-flex wow fadeInUp">
                                    <div className="card-style-two vstack tran3s w-100 mt-30">
                                        <img src="assets/image/icon/roadmap.svg" data-src="assets/image/icon/roadmap.svg" alt="roadmap" className="lazy-img icon me-auto" />
                                        <h4 className="fw-bold mt-30 mb-25">2025-2026</h4>
                                        <ul>
                                            <li><p className="mb-10">Second Metaverse Auction 2024</p></li>
                                            <li><p className="mb-10">BST Nano-Ventures Ecosystem Launch</p></li>
                                            <li><p className="mb-10">BST Academy Dubai Campus</p></li>
                                            <li><p className="mb-10">V-Commerce Platform Development</p></li>
                                            <li><p className="mb-10">Third Metaverse Auction 2025</p></li>
                                            <li><p>Neo-Banking-IBANIZED Digital Wallets</p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 d-flex wow fadeInUp" data-wow-delay="0.1s">
                                    <div className="card-style-two vstack tran3s w-100 mt-30">
                                        <img src="assets/image/icon/roadmap.svg" data-src="assets/image/icon/roadmap.svg" alt="roadmap" className="lazy-img icon me-auto" />
                                        <h4 className="fw-bold mt-30 mb-25">2027-28</h4>
                                        <ul>
                                            <li><p className="mb-10">Fourth Metaverse Auction 2026 Neo- Banking VISA/Master Cards</p></li>
                                            <li><p className="mb-10">Fifth Metaverse Auction 2027</p></li>
                                            <li><p className="mb-10">B2B Trade Finance Network Development</p></li>
                                            <li><p>Sixth Metaverse Auction 2028</p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 d-flex wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="card-style-two vstack tran3s w-100 mt-30">
                                        <img src="assets/image/icon/roadmap.svg" data-src="assets/image/icon/roadmap.svg" alt="roadmap" className="lazy-img icon me-auto" />
                                        <h4 className="fw-bold mt-30 mb-25">2029-30</h4>
                                        <ul>
                                            <li><p className="mb-10">DLT MeshNET Global Launch</p></li>
                                            <li><p className="mb-10">Seventh Metaverse Auction 2029</p></li>
                                            <li><p className="mb-10">E-Sapphire Global Ecosystem Growth</p></li>
                                            <li><p className="mb-10">Eighth Metaverse Auction 2030</p></li>
                                            <li><p>E-Sapphire Global Crypto Reserve</p></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="assets/image/icon/shape_05.svg" data-src="assets/image/icon/shape_05.svg" alt="icon" className="lazy-img shapes shape_01" />
                    <img src="assets/image/icon/shape_05.svg" data-src="assets/image/icon/shape_05.svg" alt="icon" className="lazy-img shapes shape_02" />
                </div>

                <Footer />
            </div>
        </>
    )
}